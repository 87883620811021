import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Router } from 'utils/routes';
import { RoutesRFP } from 'config/routes';

import Button from 'features/rfp/ui/Button';
import BaseModal from 'features/rfp/ui/BaseModal';
import SharePage from './SharePage';
import InstructionPage from './InstructionPage';
import useAnalytics from 'hooks/useAnalytics';

import bemCN from 'utils/bemCN';
import instructions from './instructions';
import styles from './styles.styl';

const el = bemCN(styles)('ShareModal');

const Header = ({ title, showBack, onBack }) => {
  return (
    <>
      {showBack && (
        <Button className={el('header-back-button')} onClick={onBack}>
          <img alt="Back" src="/static/images/rfp/arrow-back.svg" />
        </Button>
      )}
      <h2 className={el('header-title')}>{title || 'Why You Should Share Your Profile'}</h2>
    </>
  );
};

const PAGE = {
  share: 'share',
  instruction: 'instruction',
};

const ShareModal = ({ onClose }) => {
  const [page, setPage] = useState(PAGE.share);
  const [instructionKey, setInstructionKey] = useState();
  const [instructionTitle, setIntructionTitle] = useState();

  const isSharePage = page === PAGE.share;
  const Content = isSharePage ? SharePage : InstructionPage;

  const { query } = useRouter();
  const { instruction, domain, action } = query;

  useEffect(() => {
    if (instruction) {
      setPage(PAGE.instruction);
      setInstructionKey(instruction);
      setIntructionTitle(instructions[instruction].title);
    } else {
      setPage(PAGE.share);
      setInstructionKey(null);
      setIntructionTitle(null);
    }
  }, [instruction]);

  const analyticEvents = useAnalytics();
  const { elementClick } = analyticEvents || {};

  const goToInstruction = instruction => {
    Router.replaceRoute(RoutesRFP.Profile.name, { action, domain, instruction });
    elementClick('80lv_rfp_preview_share-instruction', instruction);
  };

  const goToShare = () => {
    Router.replaceRoute(RoutesRFP.Profile.name, { action, domain });
  };

  return (
    <BaseModal
      showClose
      className={el()}
      closeModal={onClose}
      headerClassName={el('header')}
      header={<Header title={instructionTitle} showBack={!isSharePage} onBack={goToShare} />}
      contentClassName={el('content')}
      content={<Content instructionKey={instructionKey} goToInstruction={goToInstruction} />}
    />
  );
};

ShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
