import defineActionTypes from 'utils/defineActionTypes';

export default defineActionTypes('art-builder/landing', [
  'INIT_LANDING',
  'INIT_META',
  'INIT_PROFILE',
  'INIT_PORTFOLIO',
  'INIT_COMPANY',
  'SET_PREVIEW',
  'SET_IS_FAVORITE',
  'LANDING_IS_LOAD',
  'END_LOADING',
  'UPDATE_LANDING',
  'CHANGE_LOCALE',
  'CHANGE_LANGUAGES_IN_LANDING',
  'SET_ERROR',
  'UNSET_ERROR',
]);
