import update from 'immutability-helper';

const initialState = {
  blocks: [],
  currentBlock: {},
  scrolledBlock: {},
  isSlideAdded: false,
  isActiveEditor: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'INIT_BLOCKS_DATA':
      return { ...state, blocks: action.blocks };

    case 'DELETE_BLOCK':
      return {
        ...state,
        blocks: action.blocks,
        isActiveEditor: action.isActiveEditor,
      };

    case 'DUPLICATE_BLOCK':
      return {
        ...state,
        blocks: action.blocks,
      };

    case 'CHANGE_BLOCK_BY_ID':
      return {
        ...state,
        blocks: state.blocks.map(block => {
          if (block._id !== action.blockId) {
            return block;
          }
          return {
            ...block,
            ...action.updatedBlock,
          };
        }),
      };

    case 'INIT_CURRENT_BLOCK':
      return { ...state, currentBlock: action.currentBlock };

    case 'CHECKOUT_BLOCK':
      return { ...state, currentBlock: action.currentBlock, isActiveEditor: action.isActiveEditor };

    case 'SCROLL_BLOCK':
      return { ...state, scrolledBlock: action.scrolledBlock };

    case 'CLOSE_BLOCK_SETTINGS':
      return { ...state, isActiveEditor: action.isActiveEditor };

    case 'SLIDE_ADDING':
      return { ...state, isSlideAdded: action.isSlideAdded };

    case 'SLIDE_ADDED':
      return { ...state, isSlideAdded: action.isSlideAdded };

    case 'CHECKOUT_BLOCK_BY_COMPONENT':
      return {
        ...state,
        currentBlock: action.currentBlock,
        isActiveEditor: action.isActiveEditor,
        component: action.component,
      };

    case 'CHANGE_BLOCK_DATA':
      return {
        ...state,
        currentBlock: action.currentBlock,
        blocks: state.blocks.map(block => {
          if (block._id !== action.currentBlock._id) {
            return block;
          }
          return {
            ...block,
            ...action.currentBlock,
          };
        }),
      };

    case 'CHANGE_BLOCK_BY_INDEX':
      return {
        ...state,
        currentBlock: action.currentBlock,
        blocks: update(state.blocks, { [action.blockIndex]: { $set: action.currentBlock } }),
      };

    case 'CHANGE_CURRENT_BLOCK':
      return {
        ...state,
        blocks: update(state.blocks, { [action.blockIndex]: { $set: action.currentBlock } }),
        currentBlock: action.currentBlock,
      };

    case 'ADD_BLOCK':
    case 'MOVE_BLOCK':
      return {
        ...state,
        blocks: action.blocks,
      };

    default:
      return state;
  }
}
