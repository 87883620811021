import React from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';

import styles from './styles.styl';

const cx = cn.bind(styles);

const AvatarProfile = ({ src, className }) => (
  <div className={cx('AvatartProfile', className)}>
    <Avatar className={cx('AvatartProfile__image')} image={src} />
    <span className={cx('AvatartProfile__edit')}>edit</span>
  </div>
);

AvatarProfile.defaultProps = {
  className: '',
  src: '',
};

AvatarProfile.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

export default AvatarProfile;
