import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import AppLink from 'components/AppLink';
import { setGdprCookie } from 'utils/gdpr';
import { setIsAcceptGdpr as setIsAcceptGdprAction } from 'state/gdpr/actions';
import Container from 'components/Container';
import Fade from 'components/Animations/Fade';

import styles from './styles.styl';

import Сheck from 'static/icons/check.svg';

const GdprPopup = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const setIsAcceptGdpr = useCallback(
    isAcceptGdpr => {
      setGdprCookie(isAcceptGdpr);
      dispatch(setIsAcceptGdprAction(isAcceptGdpr));
    },
    [dispatch],
  );

  return isOpen ? (
    <Fade in={isOpen}>
      <div className={styles.GdprPopup}>
        <Container>
          <div className={styles.GdprPopup__content}>
            <div className={styles.GdprPopup__contentShort}>
              <div className={styles.GdprPopup__info}>
                <h3 className={styles.GdprPopup__title}>We need your consent</h3>
                <p>
                  We use cookies on this website to make your browsing experience better. By using
                  the site you agree to our use of cookies.
                  <AppLink
                    targetBlank
                    className={styles.GdprPopup__link}
                    href="/privacy-policy?section=cookies"
                  >
                    Learn more
                  </AppLink>
                </p>
              </div>
              <div
                className={styles.GdprPopup__check}
                onClick={() => {
                  setIsAcceptGdpr(true);
                  setIsOpen(false);
                }}
              >
                <Сheck />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Fade>
  ) : null;
};

export default GdprPopup;
