import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import ErrorPage from 'components/ErrorPage';
import RegistrationProvider from 'features/registration/components/RegistrationProvider';
import SetErrorContext from '../../context/SetErrorContext';
import Footer from '../RFPFooter';
import Header from '../RFPHeader';
import styles from './styles.styl';
import JobCreationProvider from 'features/rfp/features/company/components/JobCreationProvider';

const cx = cn.bind(styles);

class RFPLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderError: false,
      setError: renderError => this.setState({ ...this.state, renderError }),
    };
  }

  getHttpStatus() {
    return this.state.renderError ? 404 : this.props.httpStatus;
  }

  componentDidCatch() {
    this.setState({
      renderError: true,
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.renderError) {
      this.setState({
        renderError: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const httpStatus = this.getHttpStatus();
    return (
      <RegistrationProvider>
        {ref => (
          <main className={cx('Layout__content')} ref={ref}>
            <JobCreationProvider>
              <Header />
              {httpStatus === 200 ? (
                <SetErrorContext.Provider value={this.state.setError}>
                  <>{children}</>
                </SetErrorContext.Provider>
              ) : (
                <ErrorPage httpStatus={httpStatus} />
              )}
              <Footer />
            </JobCreationProvider>
          </main>
        )}
      </RegistrationProvider>
    );
  }
}

RFPLayout.propTypes = {
  router: PropTypes.object,
  children: PropTypes.element,
  httpStatus: PropTypes.number,
};

export default withRouter(RFPLayout);
