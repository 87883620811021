import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadFilters } from '../state/filters/actions';
import { filtersSelector } from '../state/filters/selectors';

export const mapSelects = collection =>
  collection.map(({ id, name }) => ({ value: id, label: name }));

export default function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const { tools: possibleTools, styles: possibleStyles, categories: possibleCategories } = filters;
  const toolOptions = useMemo(() => mapSelects(possibleTools), [possibleTools]);
  const styleOptions = useMemo(() => mapSelects(possibleStyles), [possibleStyles]);
  const categoryOptions = useMemo(() => mapSelects(possibleCategories), [possibleCategories]);

  const { isLoaded } = filters;
  useEffect(() => {
    !isLoaded && dispatch(loadFilters());
  }, [isLoaded, dispatch]);

  return { toolOptions, styleOptions, categoryOptions, ...filters };
}
