import types from './types';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  items: [],
};

export default function createPortfolioReducer(
  state = initialState,
  { error, type, portfolio, portfolios, ...loadStatuses },
) {
  switch (type) {
    case types.LOAD_REQUEST:
    case types.LOAD_SUCCESS:
      return { ...state, ...loadStatuses };

    case types.LOAD_FAILURE:
      return { ...state, error, ...loadStatuses };

    case types.SET_PORTFOLIOS:
      return { ...state, items: portfolios };

    case types.ADD_PORTFOLIO:
      return {
        ...state,
        items: [...state.items, portfolio],
      };

    default:
      return state;
  }
}
