import types from './types';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  rfpCoin: {
    sku: 'rfpcoin',
    name: 'RFPCoin',
    type: 'virtual_currency',
    description: null,
    image_url: null,
    amount: 0,
  },
  rfpAnswerCoin: {
    sku: 'rfpanswercoin',
    name: 'RFP Answer Coin',
    type: 'virtual_currency',
    description: null,
    image_url: null,
    amount: 0,
  },
};

export default (state = initialState, { error, type, rfpCoin, rfpAnswerCoin, ...loadStatuses }) => {
  switch (type) {
    case types.LOAD_REQUEST:
    case types.LOAD_SUCCESS:
      return { ...state, ...loadStatuses };

    case types.LOAD_FAILURE:
      return { ...state, error, ...loadStatuses };

    case types.SET_BALANCE:
      return { ...state, rfpCoin, rfpAnswerCoin };

    default:
      return state;
  }
};
