import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useSaveScrollPositionAfterRouteChange = () => {
  const router = useRouter();
  useEffect(() => {
    if (!window) return;

    let scrollY;

    const handleRouteChangeStart = () => {
      scrollY = window.scrollY;
    };
    const handleRouteChangeComplete = () => {
      window.scrollTo(0, scrollY);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSaveScrollPositionAfterRouteChange;
