import React from 'react';
import BaseModal from 'features/rfp/ui/BaseModal';
import bemCN from 'utils/bemCN';

import styles from './AcceptModal.styl';
import Button from 'features/rfp/ui/Button';

const el = bemCN(styles)('AcceptModal');

const AcceptModal = ({ onClose, onAccept }) => {
  return (
    <BaseModal
      className={el()}
      contentClassName={el('content')}
      content={
        <>
          <h3 className={el('content-title')}>Are you sure you want to save?</h3>
          <p className={el('content-description')}>
            Any changes you made will be instantly displayed in your published job posting.
          </p>
        </>
      }
      footerClassName={el('footer')}
      footer={
        <>
          <Button
            className={el('footer-btn')}
            theme="black"
            onClick={onClose}
            isUppercase={false}
            isOutlined
          >
            Discard
          </Button>
          <Button className={el('footer-btn')} theme="black" onClick={onAccept} isUppercase={false}>
            Save changes
          </Button>
        </>
      }
    />
  );
};

export default AcceptModal;
