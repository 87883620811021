import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'features/rfp/ui/BaseModal';
import { Link } from 'utils/routes';
import { Routes80lv } from 'config/routes';

import styles from './styles.styl';
import bemCN from 'utils/bemCN';

const el = bemCN(styles)('WidgetModal');

const WidgetModal = React.forwardRef(({ closeModal, ...restProps }, ref) => {
  return (
    <BaseModal
      className={el()}
      closeModal={closeModal}
      contentClassName={el('content')}
      content={
        <div ref={ref}>
          <div id="xl_widget" style={{ maxWidth: '400px', height: '630px' }} />

          <p className={el('agreement-text')} id="agreement" style={{ display: 'none' }}>
            By signing up and using our site, you agree to our <br />{' '}
            <Link route={Routes80lv.TermsOfUse.name}>
              <a className={el('agreement-link')} target="_blank">
                Terms of Use
              </a>
            </Link>{' '}
            and our{' '}
            <Link route={Routes80lv.PrivacyPolicy.name}>
              <a className={el('agreement-link')} target="_blank">
                Privacy Policy
              </a>
            </Link>
          </p>
        </div>
      }
      {...restProps}
    />
  );
});

WidgetModal.displayName = 'WidgetModal';
WidgetModal.propTypes = {
  closeModal: PropTypes.func,
};

export default WidgetModal;
