import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { curryProps } from 'components/FinalForm';
import ClearIcon from './ClearIcon';
import RemoveIcon from './RemoveIcon';
import DropdownIcon from './DropdownIcon';
import cn from 'classnames';
import bemCN from 'utils/bemCN';
import styles from './styles.styl';

const el = bemCN(styles)('Select');

const Control = ({ isFocused, controlClassName, controlErrorClassName, error, ...props }) => (
  <components.Control
    {...props}
    className={cn(el('control', { isFocused, error: Boolean(error) }), controlClassName, {
      [controlErrorClassName]: Boolean(error),
    })}
  />
);
Control.propTypes = { isFocused: PropTypes.bool };

const ValueContainer = props => (
  <components.ValueContainer {...props} className={el('valueContainer')} />
);

const SingleValue = props => <components.SingleValue {...props} className={el('value')} />;

const IndicatorsContainer = props => (
  <components.IndicatorsContainer {...props} className={el('indicators')} />
);

const DropdownIndicator = ({ dropDownIndicatorClassName, error, ...props }) => {
  return (
    <components.DropdownIndicator {...props} className={el('indicator')}>
      {!error ? (
        <DropdownIcon
          className={cn(
            el('dropdown', { isOpen: props.selectProps.menuIsOpen }),
            dropDownIndicatorClassName,
          )}
        />
      ) : (
        <img className={el('errorIcon')} alt="error-mark" src="/static/images/rfp/error-mark.svg" />
      )}
    </components.DropdownIndicator>
  );
};
DropdownIndicator.propTypes = { selectProps: PropTypes.shape({ menuIsOpen: PropTypes.bool }) };

const ClearIndicator = ({ clearIndicatorClassName, ...props }) => {
  return (
    <components.ClearIndicator {...props} className={el('indicator')}>
      <ClearIcon className={cn(el('clearIcon'), clearIndicatorClassName)} />
    </components.ClearIndicator>
  );
};

const Menu = props => <components.Menu {...props} className={el('menu')} />;

const MenuList = ({ maxItems, maxItemsError, ...props }) => {
  const {
    selectProps: { value },
  } = props;
  const isOverloaded = useMemo(() => value && value.length >= maxItems, [maxItems, value]);
  if (isOverloaded)
    return (
      <div className={el('menuList', { isOverloaded })}>
        {maxItemsError || `Max ${maxItems} ${props.selectProps.name}.`}
      </div>
    );

  return <components.MenuList {...props} className={el('menuList')} />;
};

const Option = props => <components.Option {...props} className={el('option')} />;

const MultiValue = props => <components.MultiValue {...props} className={el('multiValue')} />;

const MultiValueContainer = props => (
  <div className={el('chip')}>
    <components.MultiValueContainer {...props} />
  </div>
);

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <div className={el('chipRemove')}>
      <RemoveIcon />
    </div>
  </components.MultiValueRemove>
);

const Placeholder = ({ isFocused, placeholderClassName, ...props }) => (
  <components.Placeholder
    {...props}
    className={cn(el('placeholder', { isFocused }), placeholderClassName)}
  />
);
Placeholder.propTypes = { isFocused: PropTypes.bool };

const Input = ({ inputClassName, ...props }) => (
  <components.Input
    {...props}
    className={cn(
      el('input', { isSelected: Boolean(props.selectProps.value.length) }),
      inputClassName,
    )}
  />
);
Input.propTypes = {
  selectProps: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.object),
  }),
};

const NoOptionsMessage = props => (
  <components.NoOptionsMessage {...props} className={el('noOptions')} />
);

const Select = ({
  value,
  isMulti,
  options,
  controlClassName,
  controlErrorClassName,
  clearIndicatorClassName,
  dropDownIndicatorClassName,
  placeholderClassName,
  maxItemsError,
  inputClassName,
  maxItems,
  error,
  name = '',
  placeholder = '',
  onChange = () => {},
  ...props
}) => (
  <ReactSelect
    name={name}
    value={value}
    isMulti={isMulti}
    options={options}
    onChange={onChange}
    hideSelectedOptions
    placeholder={placeholder}
    styles={{ menuList: () => ({ padding: 0 }) }}
    className={el()}
    components={{
      Menu,
      Input: curryProps(Input, { inputClassName }),
      Option,
      Control: curryProps(Control, {
        controlClassName,
        controlErrorClassName,
        error,
      }),
      MenuList: curryProps(MenuList, { maxItems, maxItemsError }),
      MultiValue,
      Placeholder: curryProps(Placeholder, {
        placeholderClassName,
      }),
      SingleValue,
      ClearIndicator: curryProps(ClearIndicator, {
        clearIndicatorClassName,
      }),
      ValueContainer,
      MultiValueRemove,
      NoOptionsMessage,
      DropdownIndicator: curryProps(DropdownIndicator, {
        dropDownIndicatorClassName,
        error,
      }),
      MultiValueContainer,
      IndicatorsContainer,
      IndicatorSeparator: null,
    }}
    {...props}
  />
);

Select.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
};

export default Select;
