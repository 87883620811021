import React from 'react';
import App, { Container } from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import { mediaChanged } from 'redux-mediaquery';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import { getGdprCookie } from 'utils/gdpr';
import { deviceParser } from 'utils/detectDevice';
import handleResponseStatus from 'utils/handleResponseStatus';
import { features as initialFeatures } from 'utils/features';
import Analytics from 'components/Analytics';

import { setIsAcceptGdpr } from 'state/gdpr/actions';
// Components
import Layout from 'components/Layout';

import RFPLayout from 'features/rfp/components/Layout';
import LoginProvider from 'features/auth/components/LoginProvider';
import { Auth } from 'features/auth/components/Auth';
import { initStore } from 'state';
import getThemeByRoute from 'utils/getThemeByRoute';
import FeedbackButton from 'components/FeedbackButton';
import 'spin.js/spin.css';

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    try {
      const { store, isServer, req, res } = ctx;
      const deviceDetect = deviceParser(req);
      const isAcceptGdpr = getGdprCookie(ctx);
      return await handleResponseStatus({
        promise: Promise.all([
          isAcceptGdpr !== undefined && store.dispatch(setIsAcceptGdpr(isAcceptGdpr)),
          isServer ? store.dispatch(mediaChanged({ isServer, ...deviceDetect })) : null,
          Component.getInitialProps ? Component.getInitialProps(ctx).catch(e => e) : undefined,
        ]),
        serverRes: res,
      });
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
    }
  }

  componentDidMount = () => {
    this.props.store.dispatch(mediaChanged({ isServer: false }));
  };

  render() {
    const { Component, pageProps, status, store, router } = this.props;
    const { route, query } = router;

    const theme = getThemeByRoute(route);

    return (
      <Container>
        <Provider store={store}>
          <FeatureToggles
            features={getCurrentActiveFeatureNames({
              initialFeatures,
              req: { query },
            })}
          >
            <Analytics>
              <LoginProvider>
                <Layout httpStatus={status} initialTheme={theme}>
                  <RFPLayout httpStatus={status}>
                    <Auth>
                      <Component {...pageProps} />
                      <FeedbackButton />
                    </Auth>
                  </RFPLayout>
                </Layout>
              </LoginProvider>
            </Analytics>
          </FeatureToggles>
        </Provider>
      </Container>
    );
  }
}

export default withRedux(initStore)(MyApp);
