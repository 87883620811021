import { combineReducers } from 'redux';
import questionnaires from './questionnaires';
import balance from './balance';
import info from './info';
import jobs from './jobs';
import favoriteArtists from './favoriteArtists';

const rootReducer = combineReducers({
  questionnaires,
  balance,
  info,
  jobs,
  favoriteArtists,
});

export default rootReducer;
