const TYPEFORM_QUESTION_TYPES = {
  thankyouScreen: 'thankyou_screen',
  welcomeScreen: 'welcome_screen',
  shortText: 'short_text',
  longText: 'long_text',
  statement: 'statement',
  phoneNumber: 'phone_number',
  email: 'email',
  date: 'date',
  number: 'number',
  website: 'website',
  yesNo: 'yes_no',
  multipleChoice: 'multiple_choice',
  // opinionScale: 'opinion_scale',
  fileUpload: 'file_upload',
};

const CUSTOM_QUESTION_TYPES = {
  singleChoice: 'single_choice',
};

export const QUESTION_TYPES = {
  ...TYPEFORM_QUESTION_TYPES,
  ...CUSTOM_QUESTION_TYPES,
};

const thankyouScreenModel = {
  type: QUESTION_TYPES.thankyouScreen,
  title: '',
  properties: {
    button_text: 'Back to 80 Level Talent',
  },
};
const welcomeScreenModel = {
  type: QUESTION_TYPES.welcomeScreen,
  title: '',
  properties: {
    button_text: 'Start',
  },
};
const shortTextModel = {
  type: QUESTION_TYPES.shortText,
  title: '',
  validations: {
    required: false,
  },
};
const longTextModel = {
  type: QUESTION_TYPES.longText,
  title: '',
  validations: {
    required: false,
  },
};
const statementModel = {
  type: QUESTION_TYPES.statement,
  title: '',
  properties: {
    button_text: 'Continue',
    hide_marks: true,
  },
};
const phoneNumberModel = {
  type: QUESTION_TYPES.phoneNumber,
  title: '',
  validations: {
    required: false,
  },
};
const emailModel = {
  type: QUESTION_TYPES.email,
  title: '',
  validations: {
    required: false,
  },
};
const dateModel = {
  type: QUESTION_TYPES.date,
  title: '',
  validations: {
    required: false,
  },
};
const numberModel = {
  type: QUESTION_TYPES.number,
  title: '',
  validations: {
    required: false,
  },
};
const websiteModel = {
  type: QUESTION_TYPES.website,
  title: '',
  validations: {
    required: false,
  },
};
const yesNoModel = {
  type: QUESTION_TYPES.yesNo,
  title: '',
  validations: {
    required: false,
  },
};
const multipleChoiceModel = {
  type: QUESTION_TYPES.multipleChoice,
  title: '',
  properties: {
    randomize: false,
    allow_multiple_selection: true,
    allow_other_choice: false,
    vertical_alignment: true,
    choices: [{ label: '' }],
  },
  validations: {
    required: false,
  },
};
const opinionScaleModel = {
  type: QUESTION_TYPES.opinionScale,
  title: '',
  properties: {
    start_at_one: false,
    labels: {
      left: '',
      center: '',
      right: '',
    },
    steps: 5,
  },
  validations: {
    required: false,
  },
};
const fileUploadModel = {
  type: QUESTION_TYPES.fileUpload,
  title: '',
  validations: {
    required: false,
  },
};

const singleChoiceModel = {
  type: QUESTION_TYPES.singleChoice,
  title: '',
  properties: {
    randomize: false,
    allow_multiple_selection: false,
    allow_other_choice: false,
    vertical_alignment: true,
    choices: [{ label: '' }],
  },
  validations: {
    required: false,
  },
};

export const QUESTION_MODELS = {
  [QUESTION_TYPES.thankyouScreen]: thankyouScreenModel,
  [QUESTION_TYPES.welcomeScreen]: welcomeScreenModel,
  [QUESTION_TYPES.shortText]: shortTextModel,
  [QUESTION_TYPES.longText]: longTextModel,
  [QUESTION_TYPES.statement]: statementModel,
  [QUESTION_TYPES.phoneNumber]: phoneNumberModel,
  [QUESTION_TYPES.email]: emailModel,
  [QUESTION_TYPES.date]: dateModel,
  [QUESTION_TYPES.number]: numberModel,
  [QUESTION_TYPES.website]: websiteModel,
  [QUESTION_TYPES.yesNo]: yesNoModel,
  [QUESTION_TYPES.multipleChoice]: multipleChoiceModel,
  [QUESTION_TYPES.opinionScale]: opinionScaleModel,
  [QUESTION_TYPES.fileUpload]: fileUploadModel,

  [QUESTION_TYPES.singleChoice]: singleChoiceModel,
};
