import types from './types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  formData: {
    website: '',
    title: '',
    specialisation: '',
    city: '',
    country: '',
    state: '',
  },
  error: {},
};

export default function profile(state = initialState, { error, formData, type, ...loadStatuses }) {
  switch (type) {
    case types.LOAD_REQUEST:
      return { ...state, ...loadStatuses };

    case types.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...formData,
        },
      };

    case types.LOAD_FAILURE:
      return { ...state, error, ...loadStatuses };

    default:
      return state;
  }
}
