import React from 'react';

const HornIcon = props => (
  <svg viewBox="0 0 381 386" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 43C0 19.2518 18.9968 0 42.4305 0C65.8641 0 84.8609 19.2518 84.8609 43V300C134.669 300 175.859 337.4 182.577 386H98.6755C44.1786 386 0 341.228 0 286V43ZM282.212 386H198.31C205.029 337.4 246.218 300 296.026 300V286H282.212C235.345 286 197.351 247.496 197.351 200H289.119C339.801 200 380.887 241.638 380.887 293C380.887 344.362 339.801 386 289.119 386H282.212ZM338.457 0C315.024 0 296.027 19.2518 296.027 43V186H380.888V43C380.888 19.2518 361.891 0 338.457 0ZM98.6756 100H183.537V143C183.537 166.748 164.54 186 141.106 186C117.672 186 98.6756 166.748 98.6756 143V100ZM282.212 100H197.351V186H282.212V100Z"
    />
  </svg>
);

export default HornIcon;
