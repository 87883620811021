import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { GOOGLE_MAP_KEY } from 'config/google.json';

import Preloader from 'components/Preloader';
import InputField from 'features/registration/ui/InputField';

import styles from './styles.styl';

const cx = cn.bind(styles);
const scriptId = 'google-maps-script-places';
const src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places&language=en&callback=myCallbackFunc`;

const TYPES = {
  city: 'locality',
  country: 'country',
  state: 'administrative_area_level_1',
};
const NO_RESULTS = 'ZERO_RESULTS';

const searchOptions = {
  types: ['(regions)'],
};

const formattedAddress = (...parts) => parts.filter(Boolean).join(', ');

const LocationInput = ({
  name,
  city = '',
  error = '',
  label = '',
  state = '',
  country = '',
  className = '',
  onBlur = () => {},
  placeholder = '',
  onFocus = () => {},
  inputClassName = '',
  onChange = () => {},
  FieldComponent = InputField,
}) => {
  useEffect(() => {
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = src;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(script, s);
    }
  }, []);

  const [placeId, setPlaceId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [errorPlaces, setErrorPlaces] = useState('');
  const isEmpty = errorPlaces === NO_RESULTS;
  const [address, setAddress] = useState(formattedAddress(city, state, country));

  useEffect(() => {
    setAddress(formattedAddress(city, state, country));
  }, [city, state, country]);

  const handleChange = useCallback(
    address => {
      setErrorPlaces('');
      setAddress(address);
      setIsOpen(true);
      if (!address) onChange({});
    },
    [onChange],
  );

  const handleSelect = useCallback(
    async address => {
      setIsOpen(false);

      try {
        const [result] = (await geocodeByAddress(address)) || [];
        const { address_components = [], place_id } = result;

        const { long_name: city = '' } =
          address_components.find(({ types }) => types.includes(TYPES.city)) || {};
        const { short_name: state = '' } =
          address_components.find(({ types }) => types.includes(TYPES.state)) || {};
        const { long_name: country = '' } =
          address_components.find(({ types }) => types.includes(TYPES.country)) || {};

        const { lat: location_lat, lng: location_lng } = await getLatLng(result);

        setPlaceId(place_id);
        setAddress(formattedAddress(city, state, country));
        onChange({ city, country, state, location_lat, location_lng });
      } catch (e) {
        setErrorPlaces(e);
      }
    },
    [onChange],
  );

  return (
    <>
      <PlacesAutocomplete
        name={name}
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={setErrorPlaces}
        searchOptions={searchOptions}
        googleCallbackName={`init${name}`}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={cx('LocationInput', className)}>
            <FieldComponent
              {...getInputProps()}
              name={name}
              label={label}
              onFocus={onFocus}
              onBlur={e => {
                onBlur(e);
                setIsOpen(false);
              }}
              placeholder={placeholder}
              error={error || errorPlaces}
              className={cx('LocationInput__input', inputClassName)}
            />
            <div className={cx('LocationInput__dropdown')}>
              {address && isOpen && (
                <Preloader isLoaded={!loading} className={cx('LocationInput__suggestions')}>
                  {({ className: preloaderClassName }) => (
                    <div className={preloaderClassName}>
                      {!isEmpty ? (
                        suggestions.map(suggestion => (
                          <div
                            key={suggestion.key}
                            {...getSuggestionItemProps(suggestion)}
                            className={cx('LocationInput__suggestion', {
                              'LocationInput__suggestion--active': suggestion.active,
                              'LocationInput__suggestion--selected': suggestion.placeId === placeId,
                            })}
                          >
                            {suggestion.description}
                          </div>
                        ))
                      ) : (
                        <div key="not-found" className={cx('LocationInput__suggestion')}>
                          Not found (
                        </div>
                      )}
                    </div>
                  )}
                </Preloader>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

LocationInput.propTypes = {
  city: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  onFocus: PropTypes.func,
  state: PropTypes.string,
  onChange: PropTypes.func,
  country: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  FieldComponent: PropTypes.elementType,
};

export default LocationInput;
