import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import Button from 'features/rfp/ui/Button';
import NextLink from 'components/NextLink';
import Heading from 'components/Heading';
import Page from 'components/Page';
import { RoutesRFP } from 'config/routes';
import styles from './styles.styl';

const cx = cn.bind(styles);

function NotFound() {
  return (
    <div className={cx('NotFound')}>
      <Page.Section className={cx('NotFound__error')}>
        <div className={cx('NotFound__errorContent')}>
          <div className={cx('NotFound__row')}>
            <Heading className={cx('NotFound__errorTitle')} level={1}>
              404
            </Heading>
          </div>
          <div className={cn(cx('NotFound__row'), cx('NotFound__desc'))}>
            Ooops, something went wrong
          </div>
          <div className={cn(cx('NotFound__row'))}>Page does not exist</div>
          <div className={cn(cx('NotFound__row'), cx('NotFound__button'))}>
            <NextLink route={RoutesRFP.Home}>
              <Button>MAIN PAGE</Button>
            </NextLink>
          </div>
        </div>
      </Page.Section>
    </div>
  );
}

NotFound.propTypes = {
  featured: PropTypes.object,
};

export default NotFound;
