import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button from 'features/rfp/ui/Button';
import FormError from 'features/rfp/ui/FormError';
import UploadAvatarField from './fields/profile/UploadAvatarField';
import TitleField from './fields/company/TitleField';
import WebsiteField from './fields/company/WebsiteField';
import NameField from './fields/profile/NameField';
import LastnameField from './fields/profile/LastnameField';
import EmailField from './fields/profile/EmailField';
import EmailAgreementField from './fields/profile/EmailAgreementField';

import useCompany from 'hooks/useCompany';
import useProfile from 'hooks/useProfile';
import { validateCompanyInfo } from 'components/Forms/ProfileForm/validations';
import { profileSettingsPopupEl as el } from './ProfileSettingsPopup';

const CompanySettingsForm = ({ closeModal }) => {
  const {
    updateCompany,
    // isLoading: isLoadingCompany,
    formData: companyFormData,
  } = useCompany();
  const { title, specialisation, website } = companyFormData;

  const {
    updateProfile,
    // isLoading: isLoadingProfile,
    avatar: avatarUrl,
    formData: profileFormData,
  } = useProfile();
  const { name, email, image, surname, email_agreement: emailAgreement } = profileFormData;

  const handleSubmit = async values => {
    const { title, website, ...profileValues } = values;
    const newProfileData = { ...profileFormData, ...profileValues };
    const newCompanyData = { ...companyFormData, title, website, ...location };

    await Promise.all([updateProfile(newProfileData), updateCompany(newCompanyData)]);
  };

  const isVerticalSmallTablet = useSelector(({ responsive }) => responsive.isVerticalSmallTablet);
  return (
    <div>
      <Form
        validate={validateCompanyInfo}
        onSubmit={handleSubmit}
        initialValues={{
          name,
          surname,
          email,
          image,
          title,
          specialisation,
          website,
          email_agreement: emailAgreement,
        }}
      >
        {({ dirty, valid, form: { reset }, handleSubmit, submitError }) => {
          const resetForm = e => {
            e.preventDefault();
            reset(e);
          };

          const handleSubmitForm = async e => {
            await handleSubmit(e);
            closeModal();
          };

          const cancelButton = (
            <Button theme="black" isOutlined isUppercase onClick={dirty ? resetForm : closeModal}>
              {dirty ? 'Cancel' : 'Close'}
            </Button>
          );

          const saveButton = (
            <Button
              theme="black"
              isUppercase
              onClick={handleSubmitForm}
              disabled={!dirty || !valid}
            >
              Save
            </Button>
          );

          return (
            <form className={el('form')}>
              <div className={el('action', { header: true })}>
                {!isVerticalSmallTablet && cancelButton}
                <span className={el('actionText')}>
                  {!isVerticalSmallTablet ? 'Settings' : 'Profile'}
                </span>
                {!isVerticalSmallTablet && saveButton}
              </div>
              <div className={el('content')}>
                <div className={el('upload-image')}>
                  <UploadAvatarField className={el('avatar')} url={avatarUrl} />
                </div>
                <div className={el('fields')}>
                  <TitleField />
                  <WebsiteField className={el('website')} />

                  <div className={el('person')}>
                    <p className={el('person-label')}>Contact</p>
                    <div className={el('fields')}>
                      <NameField />
                      <LastnameField />
                      <EmailField disabled />
                    </div>
                  </div>

                  <div className={el('checkbox-wrapper')}>
                    <div className={el('checkbox-empty-label')} />
                    <EmailAgreementField
                      className={el('checkbox')}
                      defaultChecked={emailAgreement}
                    />
                  </div>
                </div>

                {submitError && <FormError message={submitError} className={el('errorForm')} />}
              </div>
              {isVerticalSmallTablet && (
                <div className={el('action', { footer: true })}>
                  {cancelButton}
                  {saveButton}
                </div>
              )}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

CompanySettingsForm.propTypes = { closeModal: PropTypes.func };

export default CompanySettingsForm;
