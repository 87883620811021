import React, { Component } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames/bind';
import Router from 'next/router';
import { toast } from 'react-toastify';

// Components
import ToastContent from 'ui/Toast/ToastContent';
// Containers
import ModalContainer from 'containers/ModalContainer';
import ThemeContext from 'context/ThemeContext';
import ToastContainer from 'ui/Toast/ToastContainer';
import { ErrorStatuses as AuthErrorStatuses } from 'features/auth/config/errors';
// Styles
import '../../styles/document.styl'; // Root Styles
import styles from './style.styl';

const cx = cn.bind(styles);

const formatTitle = title => {
  const siteName = '80lv';

  return title ? `${title} - ${siteName}` : siteName;
};

class Layout extends Component {
  constructor(props) {
    super(props);

    const { initialTheme } = props;
    const defaultTheme = {
      theme: 'rfp',
      bgColor: '#000000',
      palette: { main: 'white' },
    };

    this.state = {
      renderError: false,
      rerenderKey: 0,

      themeContext: { ...defaultTheme, ...initialTheme },
    };
  }

  componentDidMount() {
    this.rerender();

    // Скрол при смене url
    Router.router.events.on('routeChangeComplete', () => {
      // Отключает скролл если передан парраметр [no-scroll]
      if (!Router.query.hasOwnProperty('no-scroll')) {
        window.scrollTo(0, 0);
      }
    });
  }

  componentDidCatch() {
    this.setState({
      renderError: true,
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.renderError) {
      this.setState({
        renderError: false,
      });
    }
  }

  rerender = () => {
    // Hack, чтобы не ломалась разметка при загрузке
    const { isOnlyDesktop, isOnlyTablet, isPhone } = this.props;

    if ((isPhone && isOnlyDesktop) || (isOnlyTablet && isOnlyDesktop)) {
      this.setState({ rerenderKey: '1' });
    }
  };

  getHttpStatus() {
    return this.state.renderError ? 'Упс! Что-то пошло не так...' : this.props.httpStatus;
  }

  toastRefCallback = ref => {
    ref && this.showToastOnError();
  };

  showToastOnError() {
    const error = Router.router.query.error;

    if (
      error !== undefined &&
      error == AuthErrorStatuses.TokenExpires &&
      !this.props.isAuthenticated
    ) {
      toast(
        <ToastContent
          type="error"
          title="Authorization lost"
          body="Your token expired. Please login again to continue working."
        />,
      );
    }
  }

  /** Смена темы сайт
   * Если первый аргумент объект, объединяется с state.themeContexxt
   *
   * @param {string} theme
   * @param {string} bgImage
   * @param {string} bgColor
   */
  changeTheme = (theme, bgImage, bgColor) => {
    const themeContext =
      typeof theme === 'object'
        ? Object.assign({}, this.state.themeContext, theme)
        : { theme, bgImage, bgColor };
    this.setState(() => ({ themeContext }));
  };

  render() {
    const { className, children, title } = this.props;

    const themeContext = this.state.themeContext;
    const contextData = { theme: themeContext, setTheme: this.changeTheme };
    const style = {
      backgroundColor: themeContext.bgColor,
      backgroundImage: themeContext.bgImage ? `url(${themeContext.bgImage})` : null,
    };

    return (
      <div
        key={this.state.rerenderKey}
        className={cx(
          'Layout',
          { [`Layout_${themeContext.theme}`]: themeContext.theme },
          className,
        )}
        style={style}
      >
        <ThemeContext.Provider value={contextData}>
          <Head>
            <title>{formatTitle(title)}</title>
          </Head>
          {children}
          <ToastContainer setRef={this.toastRefCallback} />
          <ModalContainer />
        </ThemeContext.Provider>
      </div>
    );
  }
}

Layout.defaultProps = {
  className: '',
  title: '',
  promo: {},
};

const typeColor = PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]);

Layout.propTypes = {
  initialTheme: PropTypes.shape({
    bgColor: typeColor,
    bgImage: typeColor,
    theme: PropTypes.string,
  }),
  className: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string,
  httpStatus: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isOnlyDesktop: PropTypes.bool,
  isOnlyTablet: PropTypes.bool,
  isPhone: PropTypes.bool,
  categories: PropTypes.array,
  promo: PropTypes.object,
};

const connector = connect(state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isOnlyDesktop: state.responsive.isOnlyDesktop,
  isOnlyTablet: state.responsive.isOnlyTablet,
  isPhone: state.responsive.isPhone,
}));

export default connector(Layout);
