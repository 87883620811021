import types from './types';

const loadStart = () => ({
  type: types.LOAD_REQUEST,
  isLoading: true,
});

const loadSuccess = () => ({
  type: types.LOAD_SUCCESS,
  isLoaded: true,
  isLoading: false,
});

const loadFail = error => ({
  error,
  isLoading: false,
  type: types.LOAD_FAILURE,
});

export const setPortfolios = portfolios => ({
  type: types.SET_PORTFOLIOS,
  portfolios,
});

const addPortfolio = portfolio => ({
  type: types.ADD_PORTFOLIO,
  portfolio,
});

export const createPortfolio = () => (dispatch, _, httpService) => {
  dispatch(loadStart());

  return httpService
    .get({
      url: '/rfp/portfolio/create',
    })
    .then(({ results }) => {
      dispatch(loadSuccess());
      return dispatch(addPortfolio(results));
    })
    .catch(error => dispatch(loadFail(error)));
};

export const loadPortfolios = () => (dispatch, _, httpService) => {
  dispatch(loadStart());

  return httpService
    .get({
      url: '/rfp/user_portfolios',
    })
    .then(({ results }) => {
      dispatch(loadSuccess());
      return dispatch(setPortfolios(results));
    })
    .catch(error => {
      dispatch(loadFail(error));
      throw new Error(error.message);
    });
};
