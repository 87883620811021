// TODO поменять название. Сделать более понятным
import { savePageValue } from '../../utils/api';

const ROOT = '@art-builder/pages';

export const UPDATE_PAGE = `${ROOT}/UPDATE_PAGE`;
export const INIT_PAGES_DATA = `${ROOT}/INIT_PAGES_DATA`;

const initialState = {
  pages: [],
  currentPage: 0,
};

export const changePageSettings = (propName, propValue, needSave = true) => async (
  dispatch,
  getState,
) => {
  const {
    pages: { pages, currentPage },
    landing: { landing },
  } = getState().artBuilderReducer;

  const updatedPages = { ...pages };
  updatedPages[currentPage][propName] = propValue;

  const { merchantId, projectId, _id: landingId } = landing;
  const data = {
    propName,
    propValue,
  };
  dispatch({
    type: UPDATE_PAGE,
    pages: updatedPages,
  });
  if (needSave) {
    savePageValue({
      merchantId,
      projectId,
      landingId,
      pageId: updatedPages[currentPage]._id,
      data,
    });
  }
};

export const initPages = landing => dispatch => {
  const pages = landing.pages.map(page => {
    const blocks = page.blocks.map(({ _id, module }) => ({ _id, module }));
    return {
      ...page,
      blocks,
    };
  });

  dispatch({
    type: INIT_PAGES_DATA,
    pages,
  });
};

export default function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE:
    case INIT_PAGES_DATA:
      return { ...state, pages: action.pages };
    default:
      return state;
  }
}
