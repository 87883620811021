import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Router } from 'utils/routes';
import { RoutesRFP } from 'config/routes';
import ModernModal from 'features/rfp/ui/ModernModal';
import EditJob from '../EditJob';
import CompanyLogo from '../CompanyLogo';
import Button from 'features/rfp/ui/Button';
import useCompanyInfo from '../../hooks/useCompanyInfo';
import { uploadLogo } from '../../state/info';
import { notifyError } from 'ui/Toast';
import ModalContent from '../ModalContent';
import useAnalytics from 'hooks/useAnalytics';

import bemCN from 'utils/bemCN';
import styles from './styles.styl';
import { useEffect } from 'react';

const el = bemCN(styles)('JobCreationModal');

const FormFillStep = ({ closeModal, goToLogoUploadStep }) => {
  const { customEvent } = useAnalytics();

  useEffect(() => {
    customEvent('80lv_job_create_opened');
  }, [customEvent]);

  const { logo } = useCompanyInfo();
  const handleCreate = useCallback(
    async ({ id }) => {
      customEvent('80lv_job_create_completed', { job_id: id });

      if (logo) {
        closeModal();
        // go to job card
        Router.pushRoute(RoutesRFP.Jobs.name, { id });
      } else {
        goToLogoUploadStep(id);
      }
    },
    [closeModal, customEvent, goToLogoUploadStep, logo],
  );
  return (
    <ModalContent
      title="Create a New Job"
      subtitle={
        <span>
          Tell job seekers more about the position <br /> and the opportunity
        </span>
      }
    >
      <EditJob onCreate={handleCreate} onCancel={closeModal} />
    </ModalContent>
  );
};

FormFillStep.propTypes = {
  closeModal: PropTypes.func,
  goToLogoUploadStep: PropTypes.func,
};

const logoUploadStepEl = bemCN(styles)('LogoUploadStep');

const LogoUploadStep = ({ jobId, closeModal }) => {
  const { customEvent } = useAnalytics();
  const [newLogo, setNewLogo] = useState();
  const handleLogoChange = useCallback(
    logoData => {
      customEvent('80lv_job_create_avatar-clicked', { job_id: jobId });
      return setNewLogo(logoData);
    },
    [jobId, customEvent, setNewLogo],
  );

  const dispatch = useDispatch();
  const handleMainAction = useCallback(async () => {
    if (newLogo) {
      try {
        const { error } = await dispatch(uploadLogo(newLogo));
        if (error) {
          throw error;
        }
      } catch (err) {
        notifyError(err);
      }
    }

    closeModal();
    // go to job card
    Router.pushRoute(RoutesRFP.Jobs.name, { id: jobId });
  }, [closeModal, dispatch, jobId, newLogo]);

  return (
    <ModalContent title="Company logo" subtitle="Your logo will appear on all job opportunities">
      <div className={logoUploadStepEl()}>
        <div className={logoUploadStepEl('main')}>
          <CompanyLogo onChange={handleLogoChange} />
          <p className={logoUploadStepEl('description')}>
            Image requirements <br />
            320x320 recommended <br />
            .jpg, .jpeg, .png <br />
            Max 5 mb
          </p>
        </div>
        <div className={logoUploadStepEl('footer')}>
          <Button
            className={logoUploadStepEl('footer-btn')}
            theme="black"
            isUppercase={false}
            onClick={handleMainAction}
          >
            {newLogo ? 'Finish' : 'Skip'}
          </Button>
        </div>
      </div>
    </ModalContent>
  );
};

LogoUploadStep.propTypes = {
  jobId: PropTypes.number,
  closeModal: PropTypes.func,
};

const steps = {
  formFill: 'formFill',
  logoUpload: 'logoUpload',
};

const JobCreationModal = ({ onClose }) => {
  const [step, setStep] = useState(steps.formFill);
  const [createdJobId, setCreatedJobId] = useState();
  const goToLogoUploadStep = jobId => {
    setStep(steps.logoUpload);
    setCreatedJobId(jobId);
  };

  const isFormFillStep = step === steps.formFill;
  const content = isFormFillStep ? (
    <FormFillStep closeModal={onClose} goToLogoUploadStep={goToLogoUploadStep} />
  ) : (
    <LogoUploadStep closeModal={onClose} jobId={createdJobId} />
  );
  return (
    <ModernModal
      closeModal={onClose}
      className={el()}
      contentClassName={el('content')}
      content={content}
    />
  );
};

JobCreationModal.propTypes = {
  onClose: PropTypes.func,
};

export default memo(JobCreationModal);
