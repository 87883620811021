import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useAuth from 'features/auth/hooks/useAuth';
import { getRole, Roles } from 'features/auth/utils/roles';
import { companyInfoSelector } from '../state/info/selectors';
import { getSubscriptionState, loadCompanyInfo } from '../state/info';

const useCompanyInfo = () => {
  const dispatch = useDispatch();
  const { token, roles } = useAuth();
  const info = useSelector(companyInfoSelector);
  const { isLoaded } = info;

  const role = getRole(roles);
  const isCompany = role === Roles.Company;
  const isStudio = role === Roles.Studio;

  useEffect(() => {
    if (isCompany && token && !isLoaded) {
      dispatch(getSubscriptionState());
      dispatch(loadCompanyInfo());
    }

    if (isStudio && !isLoaded) {
      dispatch(loadCompanyInfo());
    }
  }, [dispatch, token, isLoaded, isCompany, isStudio]);

  return info;
};

export default useCompanyInfo;
