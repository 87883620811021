import { combineReducers } from 'redux';

import artist from './artist';
import portfolios from './portfolios';
import questionnaire from './questionnaire';
import company from '../features/company/state';
import filters from './filters';

const rootReducer = combineReducers({
  artist,
  portfolios,
  questionnaire,
  company,
  filters,
});

export default rootReducer;
