import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button from 'features/rfp/ui/Button';
import FormError from 'features/rfp/ui/FormError';
import NameField from './fields/profile/NameField';
import LastnameField from './fields/profile/LastnameField';
import EmailField from './fields/profile/EmailField';
import TitleField from './fields/company/TitleField';
import SpecializationField from './fields/company/SpecializationField';
import WebsiteField from './fields/company/WebsiteField';
import UploadAvatarField from './fields/profile/UploadAvatarField';
import RFPProfileShowStatusField from './fields/profile/RFPProfileShowStatusField';
import EmailAgreementField from './fields/profile/EmailAgreementField';
import LocationField from './fields/LocationField';

import { formattedAddress } from 'utils/formattedAddress';
import useCompany from 'hooks/useCompany';
import useProfile from 'hooks/useProfile';
import { validateStudioInfo } from 'components/Forms/ProfileForm/validations';
import { profileSettingsPopupEl as el } from './ProfileSettingsPopup';

const StudioSettingsForm = ({ closeModal }) => {
  const {
    updateCompany,
    // isLoading: isLoadingCompany,
    formData: companyFormData,
  } = useCompany();
  const { title, specialisation, website, city, country, state } = companyFormData;

  const {
    updateProfile,
    // isLoading: isLoadingProfile,
    avatar: avatarUrl,
    formData: profileFormData,
  } = useProfile();
  const {
    name,
    email,
    image,
    surname,
    rfp_show_status: rfpShowStatus,
    email_agreement: emailAgreement,
  } = profileFormData;

  const [location, setLocation] = useState({ city, state, country });

  useEffect(() => {
    setLocation({ city, state, country });
  }, [city, country, state]);

  const handleSubmit = async values => {
    const { title, specialisation, website, ...profileValues } = values;
    const newProfileData = { ...profileFormData, ...profileValues };
    const newCompanyData = { ...companyFormData, title, specialisation, website, ...location };

    await Promise.all([updateProfile(newProfileData), updateCompany(newCompanyData)]);
  };

  const isVerticalSmallTablet = useSelector(({ responsive }) => responsive.isVerticalSmallTablet);
  return (
    <div>
      <Form
        validate={validateStudioInfo}
        onSubmit={handleSubmit}
        initialValues={{
          name,
          surname,
          email,
          image,
          title,
          specialisation,
          website,
          location: formattedAddress(city, country, state),
          rfp_show_status: rfpShowStatus,
          email_agreement: emailAgreement,
        }}
      >
        {({ dirty, valid, form: { reset }, handleSubmit, submitError }) => {
          const resetForm = e => {
            e.preventDefault();
            reset(e);
          };

          const handleSubmitForm = async e => {
            await handleSubmit(e);
            closeModal();
          };

          const cancelButton = (
            <Button theme="black" isOutlined isUppercase onClick={dirty ? resetForm : closeModal}>
              {dirty ? 'Cancel' : 'Close'}
            </Button>
          );

          const saveButton = (
            <Button
              theme="black"
              isUppercase
              onClick={handleSubmitForm}
              disabled={!dirty || !valid}
            >
              Save
            </Button>
          );

          return (
            <form className={el('form')}>
              <div className={el('action', { header: true })}>
                {!isVerticalSmallTablet && cancelButton}
                <span className={el('actionText')}>
                  {!isVerticalSmallTablet ? 'Settings' : 'Profile'}
                </span>
                {!isVerticalSmallTablet && saveButton}
              </div>
              <div className={el('content')}>
                <div className={el('upload-image')}>
                  <UploadAvatarField className={el('avatar')} url={avatarUrl} />
                </div>
                <div className={el('fields')}>
                  <TitleField label="Studio name" />
                  <SpecializationField />
                  <WebsiteField className={el('website')} label="Studio website" />
                  <LocationField
                    city={city}
                    state={state}
                    country={country}
                    onChange={setLocation}
                    label="Office location"
                  />
                  <div className={el('person')}>
                    <p className={el('person-label')}>Contact</p>
                    <div className={el('fields')}>
                      <NameField />
                      <LastnameField />
                      <EmailField disabled />
                    </div>
                  </div>

                  <div className={el('checkbox-wrapper')}>
                    <div className={el('checkbox-empty-label')} />
                    <EmailAgreementField
                      className={el('checkbox')}
                      defaultChecked={emailAgreement}
                    />
                  </div>

                  <div className={el('checkbox-wrapper')}>
                    <div className={el('checkbox-empty-label')} />
                    <RFPProfileShowStatusField
                      className={el('checkbox')}
                      defaultChecked={rfpShowStatus}
                    />
                  </div>
                </div>

                {submitError && <FormError message={submitError} className={el('errorForm')} />}
              </div>
              {isVerticalSmallTablet && (
                <div className={el('action', { footer: true })}>
                  {cancelButton}
                  {saveButton}
                </div>
              )}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

StudioSettingsForm.propTypes = { closeModal: PropTypes.func };

export default StudioSettingsForm;
