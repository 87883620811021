import FacebookIcon from '../ui/icons/FacebookIcon';
import LinkedinIcon from '../ui/icons/LinkedinIcon';
import TwitterIcon from '../ui/icons/TwitterIcon';
import PinterestIcon from '../ui/icons/PinterestIcon';
import template from 'lodash/template';

const compiledByProvider = {
  fb: template(
    'https://www.facebook.com/dialog/feed?app_id=1105739682944582&display=page&link=${link}',
  ),
  li: template(
    'https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=&summary=${title}&source=',
  ),
  tw: template('https://twitter.com/intent/tweet?url=${link}&text=${title}'),
  pin: template(
    'https://pinterest.com/pin/create/button/?url=${link}&media=${imageUrl}&description=${title}',
  ),
};

const title = 'I just joined the #80LevelTalent community. Check the link to see my work.';
const encodedTitle = encodeURIComponent(title);

const getShareLink = provider => (link, imageUrl) => {
  if (!link || !provider) return '';
  const compiled = compiledByProvider[provider];
  const shareLink = compiled({ link, title: encodedTitle, imageUrl });
  return shareLink;
};

export default [
  {
    name: 'Facebook',
    shortName: 'fb',
    Icon: FacebookIcon,
    getShareLink: getShareLink('fb'),
    shareAction: 'Share',
  },
  {
    name: 'LinkedIn',
    shortName: 'li',
    Icon: LinkedinIcon,
    getShareLink: getShareLink('li'),
    shareAction: 'Share',
  },
  {
    name: 'Twitter',
    shortName: 'tw',
    Icon: TwitterIcon,
    getShareLink: getShareLink('tw'),
    shareAction: 'Tweet',
  },
  {
    name: 'Pinterest',
    shortName: 'pin',
    Icon: PinterestIcon,
    getShareLink: getShareLink('pin'),
    shareAction: 'Pin',
  },
];
