import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('rfpReducer/artist/portfolios', [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_FAILURE',
  'ADD_PORTFOLIO',
  'SET_PORTFOLIOS',
]);
