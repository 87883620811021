const imageBasePath = '/static/images/rfp/instructions/inst';

export default {
  title: 'Instagram',
  link: 'https://www.instagram.com/',
  structure: [
    [
      {
        type: 'text',
        content: '2. Click Edit profile',
      },
      {
        type: 'image',
        content: { src: `${imageBasePath}/1.png`, alt: 'Instagram profile' },
      },
    ],
    [
      { type: 'text', content: '3. Paste your profile link into the Website field' },
      { type: 'profileLink' },
      { type: 'image', content: { src: `${imageBasePath}/2.png`, alt: 'Instagram settings' } },
    ],
    { type: 'text', content: '4. Click the Done button' },
  ],
};
