import types from './types';

const loadStart = () => ({
  type: types.LOAD_REQUEST,
  isLoading: true,
});

const loadSuccess = data => ({
  type: types.LOAD_SUCCESS,
  isLoaded: true,
  isLoading: false,
  ...data,
});

const loadFail = error => ({
  error,
  isLoading: false,
  type: types.LOAD_FAILURE,
});

export const loadFilters = () => (dispatch, getState, httpService) => {
  const { isLoading } = getState().rfpReducer.filters;
  if (isLoading) {
    return;
  }
  dispatch(loadStart());
  return httpService
    .get({
      url: `/rfp/layout`,
    })
    .then(results => dispatch(loadSuccess(results)))
    .catch(error => {
      dispatch(loadFail(error));
      throw new Error(error.message);
    });
};
