import React from 'react';

import CheckboxField from '../CheckboxField';

const RFPProfileShowStatusField = ({ defaultChecked, ...props }) => (
  <CheckboxField
    name="rfp_show_status"
    title="Show my Profile on 80.lv"
    defaultChecked={defaultChecked}
    {...props}
  />
);

export default RFPProfileShowStatusField;
