import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  subscribed: false,
  logo: null,
  title: '',
  website: '',
};

export const getSubscriptionState = createAsyncThunk(
  'info/getSubscriptionState',
  async (token, { extra: httpService }) => {
    const { results: subscribed } = await httpService.get({
      url: 'rfp/check_company_access',
      headers: { token },
    });

    return subscribed;
  },
);

export const uploadLogo = createAsyncThunk(
  'info/uploadLogo',
  async (logo, { extra: httpService }) => {
    const { results } = await httpService.post({
      url: 'company/logo',
      data: logo,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return results.logo;
  },
);

export const loadCompanyInfo = createAsyncThunk('info/load', async (_, { extra: httpService }) => {
  const { results } = await httpService.get({
    url: 'company/get',
  });
  return results;
});

const companyInfoSlice = createSlice({
  name: 'info',
  initialState,
  extraReducers: {
    [getSubscriptionState.pending]: state => {
      state.isLoading = true;
    },
    [getSubscriptionState.fulfilled]: (state, { payload: subscribed }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.subscribed = subscribed;
    },
    [getSubscriptionState.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [uploadLogo.pending]: state => {
      state.isLoading = true;
    },
    [uploadLogo.fulfilled]: (state, { payload: logo }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.logo = logo;
    },
    [uploadLogo.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [loadCompanyInfo.pending]: state => {
      state.isLoading = true;
    },
    [loadCompanyInfo.fulfilled]: (state, { payload: info }) => {
      return {
        ...state,
        ...info,
        isLoading: false,
        isLoaded: true,
      };
    },
    [loadCompanyInfo.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
  },
  reducers: {},
});

const { reducer } = companyInfoSlice;

export default reducer;
