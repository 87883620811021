const ROOT = '@art-builder/spaces';

export const CHECKOUT_SEO_SECTION = `${ROOT}/CHECKOUT_SEO_SECTION`;
export const INIT_SECTION_DATA = `${ROOT}/INIT_SECTION_DATA`;
export const INIT_SAVING_PROCESS = `${ROOT}/INIT_SAVING_PROCESS`;
export const DATA_SAVED = `${ROOT}/DATA_SAVED`;
export const DATA_DONT_SAVE = `${ROOT}/DATA_DONT_SAVE`;
export const CLEAR_SAVE_STATUS = `${ROOT}/CLEAR_SAVE_STATUS`;
export const TOGGLE_GLOBAL_THEME = `${ROOT}/TOGGLE_GLOBAL_THEME`;
export const TOGGLE_PREVIEW = `${ROOT}/TOGGLE_PREVIEW`;
export const LOADED_PREVIEW = `${ROOT}/LOADED_PREVIEW`;
export const SET_PREVIEW_SIZE = `${ROOT}/SET_PREVIEW_SIZE`;

export const saveStatusSelector = state => state.artBuilderReducer.sideMenu.saveStatus;

const initialState = {
  saveStatus: 'completed',
  isShowGlobalTheme: true,
  preview: {
    enable: false,
    type: 'laptop',
    loaded: false,
    size: {
      width: '100%',
      height: '100%',
    },
  },
  assetSection: {
    icon: 'dvr',
  },
  spaces: [
    {
      id: 'landing',
      type: 'component',
    },
    {
      id: 'globalTheme',
      type: 'component',
      hideContent: true,
    },
    {
      id: 'seo',
      type: 'link_page',
      link: '/seo',
      icon: 'share',
      current: 'favicon',
      settingsMenu: [
        {
          id: 'favicon',
          label: 'seo_page.favicon.title',
          isChecked: true,
        },
        {
          id: 'sharingOptions',
          label: 'seo_page.sharing.title',
          isChecked: false,
        },
      ],
    },
    {
      id: 'assets',
      type: 'link_curtain_right',
    },
  ],
};

export const setPreviewSize = ({ width = 1440, height = '100%', type = 'custom' }) => dispatch => {
  dispatch({
    type: SET_PREVIEW_SIZE,
    width,
    height,
    newType: type,
  });
};

export const checkoutSeoBlock = currentSectionID => (dispatch, getState) => {
  const { sideMenu } = getState().artBuilderReducer;
  const seoSection = sideMenu.spaces.find(section => section.id === 'seo');

  if (seoSection.current === currentSectionID) {
    return false;
  }

  const updatedSpaces = sideMenu.spaces.map(section => {
    const result = { ...section };
    if (section.id === 'seo') {
      result.current = currentSectionID;
    }
    return result;
  });

  dispatch({
    type: CHECKOUT_SEO_SECTION,
    spaces: [...updatedSpaces],
  });

  return true;
};

export const getSpaces = domain => dispatch => {
  const updatedSpaces = initialState.spaces.map(item => ({
    ...item,
    link: item.link ? `/sites/${domain}${item.link}` : '',
  }));
  dispatch({
    type: INIT_SECTION_DATA,
    spaces: updatedSpaces,
  });
};

export const toggleGlobalTheme = () => (dispatch, getState) => {
  const {
    sideMenu: { isShowGlobalTheme },
  } = getState().artBuilderReducer;
  dispatch({
    type: TOGGLE_GLOBAL_THEME,
    isShowGlobalTheme: !isShowGlobalTheme,
  });
};

export const initSavingProcess = () => dispatch => {
  dispatch({
    type: INIT_SAVING_PROCESS,
    saveStatus: 'saving',
  });
};

export const togglePreview = () => dispatch => {
  dispatch({
    type: TOGGLE_PREVIEW,
  });
};

export const loadedPreview = () => dispatch => {
  dispatch({
    type: LOADED_PREVIEW,
  });
};

export const clearSaveStatus = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: CLEAR_SAVE_STATUS,
      saveStatus: 'not',
    });
  }, 3000);
};

export const dataSaved = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: DATA_SAVED,
      saveStatus: 'completed',
    });
  }, 1000);
};

export const dataDontSave = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: DATA_DONT_SAVE,
      saveStatus: 'error',
    });
  }, 1000);
};

export default function spacesReducer(state = initialState, action) {
  switch (action.type) {
    case CHECKOUT_SEO_SECTION:
    case INIT_SECTION_DATA:
      return { ...state, spaces: action.spaces };
    case INIT_SAVING_PROCESS:
    case DATA_SAVED:
    case DATA_DONT_SAVE:
    case CLEAR_SAVE_STATUS:
      return { ...state, saveStatus: action.saveStatus };
    case TOGGLE_GLOBAL_THEME:
      return { ...state, isShowGlobalTheme: action.isShowGlobalTheme };
    case TOGGLE_PREVIEW:
      return {
        ...state,
        preview: { ...state.preview, enable: !state.preview.enable, loaded: false },
      };
    case LOADED_PREVIEW:
      return { ...state, preview: { ...state.preview, loaded: true } };
    case SET_PREVIEW_SIZE:
      return {
        ...state,
        preview: {
          ...state.preview,
          type: action.newType,
          size: {
            ...state.preview.size,
            width: action.width,
            height: action.height,
          },
        },
      };
    default:
      return state;
  }
}
