import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.styl';

const cx = cn.bind(styles);

const UserInfo = ({ fullName, email }) => (
  <div className={cx('UserInfo')}>
    <p>{fullName}</p>
    <p className={cx('UserInfo__email')}>{email}</p>
  </div>
);

UserInfo.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default UserInfo;
