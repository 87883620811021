import PropTypes from 'prop-types';
import React from 'react';

function InputFile({ name, children, className, onChange, ...other }) {
  return (
    <label className={className}>
      <input
        style={{ display: 'none' }}
        name={name}
        onChange={onChange}
        type="file"
        accept="image/*"
        {...other}
      />
      {children}
    </label>
  );
}

InputFile.defaultProps = {
  className: '',
};

export default InputFile;

InputFile.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
