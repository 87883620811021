import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import useFilters, { mapSelects } from 'features/rfp/hooks/useFilters';
import { formattedAddress } from 'utils/formattedAddress';
import { Field, curryProps } from 'components/FinalForm';

const JobsForm = ({ job, validate, onSubmit, ...props }) => {
  const {
    title,
    content,
    city,
    country,
    state,
    is_active,
    categories,
    tools,
    styles,
    use_external_link,
    external_link,
  } = job;

  const {
    tools: possibleTools,
    styles: possibleStyles,
    categories: possibleCategories,
    toolOptions,
    styleOptions,
    categoryOptions,
  } = useFilters();

  const [location, setLocation] = useState({ city, state, country });

  const [is_external] = useState(use_external_link);

  useEffect(() => {
    setLocation({ city, state, country });
  }, [city, country, state]);

  const TitleField = useMemo(
    () =>
      curryProps(Field, { name: 'title', showError: false, placeholder: 'Job title (required)' }),
    [],
  );
  const DescriptionField = useMemo(
    () =>
      curryProps(Field, {
        name: 'content',
        showError: false,
        placeholder: 'Job description\n- MAX 5,000 characters',
      }),
    [],
  );

  const selectedCategories = useMemo(() => {
    if (!categories) return [];
    return mapSelects(
      possibleCategories.filter(item => categories.find(cat => cat.id === item.id)),
    );
  }, [possibleCategories, categories]);

  const selectedTools = useMemo(() => {
    if (!tools) return [];
    return mapSelects(possibleTools.filter(item => tools.find(tool => tool.id === item.id)));
  }, [possibleTools, tools]);

  const selectedStyles = useMemo(() => {
    if (!styles) return [];
    return mapSelects(possibleStyles.filter(item => styles.find(style => style.id === item.id)));
  }, [possibleStyles, styles]);

  const LocationField = useMemo(
    () =>
      curryProps(Field, {
        city,
        state,
        country,
        name: 'jobLocation',
        onChange: fn => (v = {}) => {
          const { city, state, country } = v;
          fn(formattedAddress(city, state, country));
          setLocation(v);
        },
        placeholder: 'Location',
        showError: false,
      }),
    [city, country, state],
  );

  const IsActiveField = useMemo(
    () =>
      curryProps(Field, {
        name: 'is_active',
        title: 'Is active',
        is_active,
        defaultChecked: is_active,
        type: 'checkbox',
        showError: false,
      }),
    [is_active],
  );

  const CategoriesField = useMemo(
    () =>
      curryProps(Field, {
        name: 'categories',
        title: 'Categories',
        placeholder: 'Select skills',
        isMulti: true,
        options: categoryOptions,
        showError: false,
        onFocus: () => {},
      }),
    [categoryOptions],
  );

  const ToolsField = useMemo(
    () =>
      curryProps(Field, {
        name: 'tools',
        title: 'Tools',
        placeholder: 'Select tools',
        isMulti: true,
        options: toolOptions,
        showError: false,
        onFocus: () => {},
      }),
    [toolOptions],
  );

  const StylesField = useMemo(
    () =>
      curryProps(Field, {
        name: 'styles',
        title: 'Styles',
        placeholder: 'Select styles',
        isMulti: true,
        options: styleOptions,
        showError: false,
        onFocus: () => {},
      }),
    [styleOptions],
  );

  const ExternalLinkCheckboxField = useMemo(
    () =>
      curryProps(Field, {
        name: 'use_external_link',
        title: 'I would prefer to receive the application on my site',
        is_external,
        defaultChecked: is_external,
        type: 'checkbox',
        showError: false,
      }),
    [is_external],
  );

  const ExternalLinkField = useMemo(
    () => curryProps(Field, { name: 'external_link', showError: true, placeholder: 'https://' }),
    [],
  );

  return (
    <FinalForm
      initialValues={{
        title,
        content: content && content[0] && content[0].content ? content[0].content.text : '',
        is_active,
        categories: selectedCategories,
        tools: selectedTools,
        styles: selectedStyles,
        jobLocation: formattedAddress(city, state, country),
        use_external_link: is_external || false,
        external_link: external_link || '',
      }}
      validate={validate}
      onSubmit={data => onSubmit({ ...data, ...location })}
    >
      {({ submitError, handleSubmit, ...form }) =>
        props.render({
          TitleField,
          DescriptionField,
          IsActiveField,
          LocationField,
          CategoriesField,
          ToolsField,
          StylesField,
          ExternalLinkCheckboxField,
          ExternalLinkField,
          submitError,
          handleSubmit,
          form,
        })
      }
    </FinalForm>
  );
};

JobsForm.defaultProps = {
  onSubmit: null,
};

JobsForm.propTypes = {
  job: PropTypes.object,
  onSubmit: PropTypes.func,
  render: PropTypes.func,
  validate: PropTypes.func,
};

export default JobsForm;
