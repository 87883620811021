import axios from 'axios';

export const BASE_URL = process.env.SITEBUILDER_API_URL;

const requestErrorHandler = promise => promise.then(res => res).catch(error => error.response);

export const getAssets = async ({ merchantId, projectId, collectionId, collectionName = 'site' }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${collectionName}`,
      method: 'get',
      withCredentials: true,
    }),
  );

export const uploadAsset = async ({
  merchantId,
  projectId,
  collectionId,
  collectionName = 'site',
  data,
}) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${collectionName}`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  );

export const deleteAsset = async ({ merchantId, projectId, collectionId, id }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${id}`,
      method: 'delete',
      withCredentials: true,
    }),
  );
