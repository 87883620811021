import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import UploadIcon from 'features/rfp/ui/icons/UploadIcon';
import InputFile from 'components/InputFile';
import { notifyError } from 'ui/Toast';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';
import IconButton from 'features/rfp/ui/IconButton';

const el = bemCN(styles)('CompanyLogo');

const CompanyLogo = ({ className, small = false, onChange, src, onClick }) => {
  const [logoSrc, setLogoSrc] = useState(src);
  useEffect(() => {
    if (src) {
      setLogoSrc(src);
    }
  }, [src]);
  const handleChange = useCallback(
    async ({ nativeEvent }) => {
      const logoFile = nativeEvent.target.files[0];

      const data = new FormData();
      data.append('image', logoFile, logoFile.name);

      const maxSize = 5242880;
      if (logoFile.size > maxSize) {
        notifyError({ message: 'Your logo upload failed. The image size should not exceed 5mb' });
        return;
      }
      setLogoSrc(URL.createObjectURL(logoFile));
      onChange && onChange(data);
    },
    [onChange],
  );
  return (
    <div className={cn(el({ small }), className)}>
      {onChange && (
        <InputFile
          name="logo"
          onChange={handleChange}
          className={cn(el('input'), el('icon-wrapper'))}
          accept="image/png, image/jpeg"
        >
          <UploadIcon />
        </InputFile>
      )}
      {onClick && (
        <IconButton className={el('icon-wrapper')} onClick={onClick}>
          <UploadIcon />
        </IconButton>
      )}
      {logoSrc && <img alt="company logo" src={logoSrc} className={el('image')} />}
    </div>
  );
};

CompanyLogo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  small: PropTypes.bool,
};

export default CompanyLogo;
