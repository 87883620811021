import React from 'react';
import PropTypes from 'prop-types';
import Button from 'features/rfp/ui/Button';
import Toast from 'features/rfp/ui/Toast';
import bemCN from 'utils/bemCN';
import cn from 'classnames';

import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import styles from './styles.styl';
const el = bemCN(styles)('CopyToClipboardField');

const CopyToClipboardField = ({ text, className, onCopy, value, ...props }) => {
  const handleCopyClick = () => {
    copy(text);
    toast(<Toast type="success" title="Success" body="Your link has been copied" />, {
      autoClose: 3000,
    });
    onCopy && onCopy();
  };
  return (
    <label className={cn(el(), className)} {...props}>
      <input className={el('input')} readOnly value={value || text} />
      <Button className={el('icon-button')} onClick={handleCopyClick}>
        <img alt="Copy" src="/static/images/rfp/copy.svg" />
      </Button>
    </label>
  );
};

CopyToClipboardField.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  onCopy: PropTypes.func,
};

export default CopyToClipboardField;
