import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styles from './styles.styl';

const cx = cn.bind(styles);

const InputField = ({
  name,
  error,
  label,
  isLink,
  disabled,
  type = 'text',
  className = '',
  placeholder = '',
  defaultValue = '',
  onChange = () => {},
  ...input
}) => {
  const [focus, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!focus);

  return (
    <div className={cx('InputField', className)}>
      {label && (
        <label
          htmlFor={name}
          className={cx('InputField__label', { 'InputField__label--error': !disabled && error })}
        >
          {!disabled && error ? error : label}
        </label>
      )}
      <div
        className={cx('InputField__container', {
          'InputField__container--focus': focus,
          'InputField__container--disabled': disabled,
          'InputField__container--error': error,
        })}
      >
        {isLink && <div className={cx('InputField__http')}>http://</div>}
        <input
          id={name}
          type={type}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={cx('InputField__input')}
          {...input}
          onBlur={e => {
            if (input.onBlur) input.onBlur(e);
            toggleFocus();
          }}
          onFocus={e => {
            if (input.onFocus) input.onFocus(e);
            toggleFocus();
          }}
        />
        {!disabled && error && (
          <img
            alt="error-mark"
            src="/static/images/rfp/error-mark.svg"
            className={cx('InputField__error-mark')}
          />
        )}
      </div>
    </div>
  );
};

InputField.propTypes = {
  isLink: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default InputField;
