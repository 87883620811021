import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('ModalContent');

const ModalContent = ({ title, subtitle, children, className, mainClassName }) => {
  return (
    <div className={cn(el('container'), className)}>
      {title && <h3 className={el('title')}>{title}</h3>}
      {subtitle && <p className={el('subtitle')}>{subtitle}</p>}
      <div className={cn(el('main'), mainClassName)}>{children}</div>
    </div>
  );
};

ModalContent.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  mainClassName: PropTypes.string,
};

export default ModalContent;
