import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadPortfolios as load,
  createPortfolio as create,
} from '../state/artist/portfolios/actions';
import { portfoliosSelector as selector } from '../state/artist/portfolios/selectors';
import useAuth from 'features/auth/hooks/useAuth';
import useRetries from 'hooks/useRetries';

export default function useCurrentArtistPortfolios() {
  const { token } = useAuth();
  const dispatch = useDispatch();

  const { isLoaded, isLoading, items: portfolios } = useSelector(selector);

  const loadPortfolios = useCallback(() => dispatch(load()), [dispatch]);
  const createPortfolio = useCallback(() => dispatch(create()), [dispatch]);
  const { retry: retryLoadPortfolios } = useRetries({ fn: loadPortfolios });

  useEffect(() => {
    if (token && !isLoaded && !isLoading) {
      retryLoadPortfolios();
    }
  }, [dispatch, isLoaded, isLoading, retryLoadPortfolios, token]);

  return {
    isLoading,
    portfolios,
    loadPortfolios,
    createPortfolio,
  };
}
