import React from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

import LogoIcon from 'ui/icons/LogoIcon';
import styles from './style.styl';

const cx = cn.bind(styles);

const Logo = ({ className, ...other }) => (
  <div className={cx('Logo')}>
    <LogoIcon className={cx('Logo__icon', className)} {...other} />
  </div>
);

Logo.defaultProps = {
  className: '',
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
