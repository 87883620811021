import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  offset: 0,
  portfolios: [],
};

const DEFAULT_COUNT = 12;
export const loadFavoriteArtists = createAsyncThunk(
  'favoriteArtists/load',
  async ({ count = DEFAULT_COUNT }, { extra: httpService, getState }) => {
    const { offset, portfolios } = getState().rfpReducer.company.favoriteArtists;
    if (portfolios.length && !offset) {
      return;
    }

    const response = await httpService.get({
      url: '/rfp/portfolios',
      data: {
        offset,
        count,
        is_favorite: true,
        sort: '-favorite',
      },
    });

    return response;
  },
);

export const setFavorite = createAsyncThunk(
  'favoriteArtists/setFavorite',
  async ({ domain, isFavorite }, { extra: httpService }) => {
    await httpService.post({
      url: '/rfp/company/portfolio/favorite',
      data: {
        domain,
        action: isFavorite ? 'add' : 'remove',
      },
    });
  },
);

const favoriteArtists = createSlice({
  name: 'favoriteArtists',
  initialState,
  extraReducers: {
    [loadFavoriteArtists.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [loadFavoriteArtists.fulfilled]: (state, { payload = { results: [] } }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.portfolios.push(...payload.results);
      state.offset = payload.next;
    },
    [loadFavoriteArtists.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [setFavorite.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [setFavorite.fulfilled]: (
      state,
      {
        meta: {
          arg: { domain, isFavorite },
        },
      },
    ) => {
      state.isLoading = false;
      state.isLoaded = true;

      if (!isFavorite) {
        const updatedItems = state.portfolios.filter(item => item.portfolio.domain !== domain);
        state.portfolios = updatedItems;
        if (state.offset > 1) {
          state.offset = state.offset - 1;
        }
      }
    },
    [setFavorite.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
  },
  reducers: {},
});

const { reducer } = favoriteArtists;

export default reducer;
