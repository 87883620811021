import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { Router } from 'utils/routes';
import Logo from 'ui/Logo';
import Profile from './Profile';
import NextLink from 'components/NextLink';
import Button from '../../ui/Button';

import { RoutesRFP } from 'config/routes';
import { getToken, getTokenData, getUserRolesFromTokenGroups } from 'features/auth/utils/auth';
import { Roles, getRole } from 'features/auth/utils/roles';
import useTheme from 'hooks/useTheme';

import bemCN from 'utils/bemCN';
import useAuth from 'features/auth/hooks/useAuth';
import useRegistration from 'features/registration/hooks/useRegistration';
import useAnalytics from 'hooks/useAnalytics';
import styles from './styles.styl';

const el = bemCN(styles)('RFPHeader');

function RFPHeader() {
  const { theme } = useTheme();
  const {
    palette: { main },
    bgColor,
  } = theme;

  const router = useRouter();
  const { route } = router;
  const isProfilePage = useMemo(() => route === RoutesRFP.Profile.page, [route]);

  const { currentRole, isAuthenticated } = useAuth();
  const isCompany = useMemo(() => currentRole === Roles.Company, [currentRole]);

  const isShowAllArtistsLink = useMemo(() => isProfilePage && (isCompany || !isAuthenticated), [
    isAuthenticated,
    isCompany,
    isProfilePage,
  ]);

  const { elementClick } = useAnalytics();
  const { openRegistration } = useRegistration();
  const handleAllArtistsLinkClick = () => {
    if (isAuthenticated) {
      Router.pushRoute(RoutesRFP.Home.name);
    } else {
      openRegistration('company');
    }
    elementClick('80lv_rfp_profile_all-artists');
  };

  const cookieToken = getToken();
  const data = getTokenData(cookieToken);
  const roles = getUserRolesFromTokenGroups(data.groups);
  const role = getRole(roles);

  const isJobSeeker = useMemo(() => role === Roles.Artist || role === Roles.Studio, [role]);

  const logoRoute = useMemo(
    () => (isJobSeeker ? RoutesRFP.ArtistHome.pattern : RoutesRFP.Home.pattern),
    [isJobSeeker],
  );

  const Link = useMemo(() => {
    if (isJobSeeker) {
      return NextLink;
    }
    return 'a';
  }, [isJobSeeker]);

  return (
    <header className={el()} style={{ backgroundColor: bgColor }}>
      <div className={el('links')}>
        <Link route={logoRoute} href={logoRoute}>
          <Logo fill={main} />
        </Link>
        {isShowAllArtistsLink && (
          <Button
            className={el('link')}
            theme="black"
            isOutlined
            onClick={handleAllArtistsLinkClick}
          >
            All Job-seekers
          </Button>
        )}
      </div>

      <Profile palette={main} />
    </header>
  );
}

export default RFPHeader;
