import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('rfpReducer/portfolios', [
  'SET_OFFSET',
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_FAILURE',
  'SET_PORTFOLIOS',
  'CLEAR_PORTFOLIOS',
  'SET_FAVORITE',
]);
