import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field as FinalFormField } from 'react-final-form';

import FieldError from './FieldError';

const UploadImageField = ({
  name,
  url,
  component: Component,
  cover: Cover,
  className,
  errorClassName,
}) => {
  const [src, setSrcState] = useState(url);
  const changeInputFile = ({ target }, onChange) => {
    const [file] = target.files || [];
    const reader = new FileReader();
    reader.onload = function(event) {
      setSrcState(event.target.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      onChange(file);
    }
  };
  return (
    <FinalFormField name={name}>
      {({ input, meta }) => (
        <>
          <Component name={name} onChange={e => changeInputFile(e, input.onChange)}>
            <Cover className={className} src={src} />
          </Component>
          <FieldError className={errorClassName} meta={meta} />
        </>
      )}
    </FinalFormField>
  );
};

UploadImageField.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  component: PropTypes.elementType,
  cover: PropTypes.elementType,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default UploadImageField;
