import React from 'react';
import PropTypes from 'prop-types';

import ProfileLink from '../ProfileLink';
import Button from 'features/rfp/ui/Button';

import ShareButtons from '../ShareButtons';
import HornIcon from 'features/rfp/ui/icons/HornIcon';

import bemCN from 'utils/bemCN';

import instructions from './instructions';

import styles from './SharePage.styl';
const el = bemCN(styles)('SharePage');

const instructionKeys = Object.keys(instructions);

const HeartIcon = props => <img alt="heart" src="/static/images/rfp/heart.svg" {...props} />;
const MegaphoneIcon = props => (
  <img alt="megaphone" src="/static/images/rfp/megaphone.svg" {...props} />
);

const SharePage = ({ goToInstruction }) => {
  const profileLink = location && location.origin + location.pathname;

  const promotions = [
    {
      icon: HornIcon,
      keyWord: 'SHOWCASED',
      text: 'ON 80 LEVEL WEBSITE',
    },
    {
      icon: HeartIcon,
      keyWord: 'PROMOTED',
      text: 'ON 80 LEVEL SOCIAL NETWORKS',
    },
    {
      icon: MegaphoneIcon,
      keyWord: 'FEATURED',
      text: 'IN 80 LEVEL TALENT ADVERTISING',
    },
  ];

  return (
    <div className={el()}>
      <h3 className={el('title')}>In your contact information</h3>
      <p className={el('description')}>
        Adding your profile link to your contact information, social networks, and other
        professional accounts increases your visibility to hiring companies. Shared profiles may
        also be included in upcoming 80LV promotions, like:
      </p>
      <div className={el('promotions')}>
        {promotions.map(({ keyWord, text, icon: Icon }) => (
          <div key={keyWord} className={el('promotion')}>
            <span className={el('promotion-icon-wrapper')}>
              <Icon
                className={el('promotion-icon', {
                  ['init-size']: keyWord === 'FEATURED',
                })}
              />
            </span>
            <span className={el('promotion-text')}>
              <span className={el('key-word')}>{keyWord} </span>
              {text}
            </span>
          </div>
        ))}
      </div>
      <div className={el('share-link')}>
        <ProfileLink />
        <div className={el('share-link-how-to')}>
          <p className={el('share-link-how-to-title')}>
            How to link your profile to other accounts:
          </p>
          {instructionKeys.map(key => (
            <Button
              isUppercase={false}
              className={el('share-link-how-to-button')}
              key={key}
              onClick={() => goToInstruction(key)}
            >
              {instructions[key].title}
            </Button>
          ))}
        </div>
      </div>
      <div className={el('share-in-socials')}>
        <h3 className={el('title')}>Share your profile in social network posts</h3>
        <ShareButtons link={profileLink} />
      </div>
    </div>
  );
};

SharePage.propTypes = {
  goToInstruction: PropTypes.func,
};

export default SharePage;
