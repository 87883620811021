import types from './types';

const initialState = {
  error: null,
  profiles: {},
  isLoaded: false,
  isLoading: false,
};

export default function articles(
  state = initialState,
  { articles, authorSlug, domain, error, type, ...loadStatuses },
) {
  switch (type) {
    case types.LOAD_ARTICLES_REQUEST:
      return { ...state, ...loadStatuses };

    case types.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        profiles: { ...state.profiles, [domain]: { articles, authorSlug } },
        ...loadStatuses,
      };

    case types.LOAD_ARTICLES_FAILURE:
      return { ...state, error, ...loadStatuses };

    default:
      return state;
  }
}
