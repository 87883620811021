import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Dropdown from 'ui/Dropdown';
import Navigation from '../../Navigation';
import UserInfo from '../../UserInfo';
import ProfileSettingsPopup from '../../ProfileSettingsPopup';

import useModal from 'hooks/useModal';

import styles from './styles.styl';

const Popup = ({ button, className, email, fullName }) => {
  const { isOpen, closeModal, Modal, openModal } = useModal();
  const openProfileSettings = useCallback(
    e => {
      openModal(e);
    },
    [openModal],
  );
  return (
    <>
      <Dropdown
        className={cn(styles.Dropdown)}
        button={toggle => button(toggle)}
        content={toggle => (
          <div className={cn(styles.Popup, className)} onClick={e => e.stopPropagation()}>
            <UserInfo email={email} fullName={fullName} />
            <Navigation toggle={toggle} openProfileSettings={openProfileSettings} />
          </div>
        )}
      />
      {isOpen && (
        <Modal>
          <ProfileSettingsPopup closeModal={closeModal} />
        </Modal>
      )}
    </>
  );
};

Popup.defaultProps = {
  fullName: '',
  className: '',
};

Popup.propTypes = {
  fullName: PropTypes.string,
  domain: PropTypes.string,
  className: PropTypes.string,
  button: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default Popup;
