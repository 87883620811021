import curry from 'ramda/es/curry';
import cn from 'classnames/bind';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import NextLink from 'components/NextLink';
import { RoutesRFP } from 'config/routes';
import useAuth from 'features/auth/hooks/useAuth';
import { Roles } from 'features/auth/utils/roles';

import styles from './styles.styl';

const cx = cn.bind(styles);

const linkCurry = curry((onClick, props) => <NextLink onClick={onClick} {...props} />);

const Navigation = ({ className, toggle, openProfileSettings }) => {
  const LinkWithToggle = useMemo(() => linkCurry(toggle), [toggle]);
  const { currentRole, logout } = useAuth();

  return (
    <nav className={cx('Navigation', className)}>
      <div className={cx('Navigation__menu')}>
        {currentRole === Roles.Company && (
          <LinkWithToggle className={styles.Link} route={RoutesRFP.Jobs}>
            My jobs
          </LinkWithToggle>
        )}
        {currentRole === Roles.Company && (
          <LinkWithToggle className={styles.Link} route={RoutesRFP.FavoriteArtists}>
            Favorite Candidates
          </LinkWithToggle>
        )}
        {(currentRole === Roles.Artist || currentRole === Roles.Studio) && (
          <LinkWithToggle className={styles.Link} route={RoutesRFP.DefaultArtBuilder}>
            My profile
          </LinkWithToggle>
        )}
        <div className={styles.Link} onClick={openProfileSettings}>
          Settings
        </div>
        {currentRole === Roles.Company && (
          <LinkWithToggle className={styles.Link} route={RoutesRFP.PricingPage}>
            Pricing
          </LinkWithToggle>
        )}
      </div>
      <LinkWithToggle
        href="#"
        onClick={e => {
          e.preventDefault();
          logout();
          Router.replace(RoutesRFP.Home.pattern, RoutesRFP.Home.pattern, { shallow: true });
        }}
        className={cn(styles.Link, styles.Navigation__logout)}
      >
        Log out
      </LinkWithToggle>
    </nav>
  );
};

Navigation.defaultProps = {
  className: '',
};

Navigation.propTypes = {
  toggle: PropTypes.func,
  openProfileSettings: PropTypes.func,
  domain: PropTypes.string,
  className: PropTypes.string,
};

export default Navigation;
