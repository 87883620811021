const isArray = value => Array.isArray(value);
const isObject = value => value === Object(value);

const isBlob = value =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

const isFile = value =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

const objectToFormData = object => {
  const formData = new window.FormData();

  Object.keys(object).forEach(key => {
    const item = object[key];
    if ((isArray(item) || isObject(item)) && !isFile(item)) {
      const stringified = JSON.stringify(item, (_, v) => v);
      formData.append(key, stringified);
    } else {
      formData.append(key, item);
    }
  });

  return formData;
};

export default objectToFormData;
