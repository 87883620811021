import React from 'react';

const CopyLinkIcon = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.81474 13.1855C1.77334 12.1441 1.77334 10.4556 2.81474 9.41422L4.22895 8.00001C4.4893 7.73966 4.91141 7.73966 5.17176 8.00001C5.43211 8.26036 5.43211 8.68247 5.17176 8.94282L3.75755 10.357C3.23685 10.8777 3.23685 11.7219 3.75755 12.2426C4.27825 12.7633 5.12247 12.7633 5.64317 12.2426L7.52878 10.357C8.04948 9.83633 8.04948 8.99211 7.52878 8.47141C6.48739 7.43001 6.48739 5.74157 7.52878 4.70017L9.4144 2.81456C10.4558 1.77316 12.1442 1.77316 13.1856 2.81456C14.227 3.85595 14.227 5.54439 13.1856 6.58579L11.7714 8.00001C11.5111 8.26036 11.089 8.26036 10.8286 8.00001C10.5683 7.73966 10.5683 7.31755 10.8286 7.0572L12.2428 5.64298C12.7635 5.12228 12.7635 4.27806 12.2428 3.75737C11.7221 3.23667 10.8779 3.23667 10.3572 3.75737L8.47159 5.64298C7.95089 6.16368 7.95089 7.0079 8.47159 7.5286C9.51299 8.57 9.51299 10.2584 8.47159 11.2998L6.58598 13.1855C5.54458 14.2269 3.85614 14.2269 2.81474 13.1855Z"
    />
  </svg>
);

export default CopyLinkIcon;
