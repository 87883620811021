import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QUESTION_MODELS, QUESTION_TYPES } from 'components/JobQuestionnairePage/constants';
import { toNumber, uniqueId } from 'lodash';

const initialState = {
  isLoading: false,
  isLoaded: false,
  previewUrl: '',
  structure: {},
  builder: {
    past: [],
    present: { thankyou_screen: null, welcome_screen: null, fields: [] },
    future: [],
    error: false,
  },
  error: {
    message: '',
  },
};

const changeValues = (array, from, to) => {
  const copy = [...(array || [])];
  const value = copy[from];
  copy.splice(from, 1);
  copy.splice(to, 0, value);
  return copy;
};

const replaceHiddenFields = (str, hiddenFields = {}) =>
  Object.entries(hiddenFields).reduce(
    (acc, [hiddenField, value]) => acc.replaceAll(hiddenField, value),
    str,
  );

const isScreenType = type =>
  [QUESTION_TYPES.welcomeScreen, QUESTION_TYPES.thankyouScreen].includes(type);

export const loadQuestionnairePreviewUrl = createAsyncThunk(
  'questionnaire/loadPreviewUrl',
  async (id, { extra: httpService }) => {
    const response = await httpService.get({
      url: `/rfp/jobs/request/${id}`,
    });
    return response?.results?.url;
  },
);

export const loadQuestionnaireStructure = createAsyncThunk(
  'questionnaire/loadStructure',
  async (id, { extra: httpService, getState }) => {
    const {
      company,
      rfpReducer: {
        company: { jobs },
      },
      profile,
    } = getState();

    const job = jobs.byIds[toNumber(id)];
    const hiddenFieldsMap = {
      '{{hidden:recruiter_name}}': profile.formData.name,
      '{{hidden:position_name}}': job.title,
      '{{hidden:company_name}}': company.formData.title,
      '{{hidden:job_country}}': job.country,
    };

    const structure = await httpService.get({
      url: `/rfp/jobs/${id}/form`,
    });

    const { welcome_screens, thankyou_screens, fields } = structure;
    let newStructure = { ...structure };

    const welcomeScreen = welcome_screens?.[0];
    const thankYouScreen = thankyou_screens?.[0];

    if (welcomeScreen) {
      newStructure.welcome_screens[0] = {
        ...welcomeScreen,
        title: replaceHiddenFields(welcomeScreen.title, hiddenFieldsMap),
      };
    }
    if (thankYouScreen) {
      newStructure.thankyou_screens[0] = {
        ...thankYouScreen,
        title: replaceHiddenFields(thankYouScreen.title, hiddenFieldsMap),
      };
    }
    if (fields && fields.length > 0) {
      const newFields = fields.map(field => ({
        ...field,
        title: replaceHiddenFields(field.title, hiddenFieldsMap),
      }));

      newStructure.fields = newFields;
    }

    return newStructure;
  },
  {
    condition: (_, { getState }) => {
      const {
        rfpReducer: { questionnaire },
      } = getState();
      const { isLoading } = questionnaire;
      if (isLoading) {
        return false;
      }
    },
  },
);

export const updateQuestionnaireStructure = createAsyncThunk(
  'questionnaire/updateStructure',
  async ({ id, structure }, { extra: httpService }) => {
    const response = await httpService.post({
      url: `/rfp/jobs/${id}/form`,
      data: structure,
    });
    return response;
  },
);

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    remove(state, { payload: { type, id, tempId } }) {
      const { past, present } = state.builder;
      const newPast = [...past, present];
      if (isScreenType(type)) {
        const newState = {
          ...state,
          builder: {
            ...state.builder,
            past: newPast,
            present: {
              ...state.builder.present,
              [type]: null,
            },
          },
        };
        return newState;
      }

      const currentFields = state.builder.present.fields;
      const newFields = currentFields.filter(field => {
        if (field.tempId) {
          return field.tempId !== tempId;
        }
        return field.id !== id;
      });
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    add(state, { payload: type }) {
      const { present, past } = state.builder;
      const newPast = [...past, present];
      const questionModel = { tempId: uniqueId(), ...QUESTION_MODELS[type] };
      if (isScreenType(type)) {
        const newState = {
          ...state,
          builder: {
            ...state.builder,
            past: newPast,
            present: {
              ...state.builder.present,
              [type]: questionModel,
            },
          },
        };
        return newState;
      }

      const currentFields = state.builder.present.fields;
      const newFields = currentFields.concat(questionModel);
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    moveFields(state, { payload: { from, to } }) {
      const { fields } = state.builder.present;
      const { present, past } = state.builder;

      const newPast = [...past, present];
      const newFields = changeValues(fields, from, to);
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    addDescription(state, action) {
      const questionInd = action.payload;
      const { fields } = state.builder.present;
      const { present, past } = state.builder;
      const newPast = [...past, present];
      const newFields = fields.map((field, index) => {
        if (index === questionInd) {
          return {
            ...field,
            properties: {
              ...field.properties,
              description: '',
            },
          };
        }

        return field;
      });
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    removeDescription(state, action) {
      const questionIndex = action.payload;
      const { fields } = state.builder.present;
      const { present, past } = state.builder;
      const newPast = [...past, present];
      const newFields = fields.map((field, index) => {
        if (index === questionIndex) {
          return {
            ...field,
            properties: {
              ...field.properties,
              description: undefined,
            },
          };
        }

        return field;
      });
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    addChoice(state, action) {
      const questionInd = action.payload;
      const { fields } = state.builder.present;
      const { present, past } = state.builder;
      const newPast = [...past, present];
      const newFields = fields.map((field, index) => {
        if (index === questionInd) {
          return {
            ...field,
            properties: {
              ...field.properties,
              choices: [...field.properties.choices, { label: '' }],
            },
          };
        }

        return field;
      });
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    removeChoice(state, action) {
      const { questionIndex, choiceIndex } = action.payload;
      const { fields } = state.builder.present;
      const { present, past } = state.builder;
      const newPast = [...past, present];
      const newFields = fields.map((field, index) => {
        if (index === questionIndex) {
          return {
            ...field,
            properties: {
              ...field.properties,
              choices: field.properties.choices.filter((_, index) => index !== choiceIndex),
            },
          };
        }

        return field;
      });
      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },
    undo(state) {
      const { past, present, future } = state.builder;
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      return {
        ...state,
        builder: {
          past: newPast,
          present: previous,
          future: [present, ...future],
        },
      };
    },

    update(state, { payload: newPresent }) {
      const newPast = [...state.builder.past, state.builder.present];

      return {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: newPresent,
        },
      };
    },

    changeQuestionType(state, { payload: { index: questionInd, newType } }) {
      const { present, past } = state.builder;
      const { fields } = state.builder.present;
      const newPast = [...past, present];

      const tempId = uniqueId();
      const questionModel = QUESTION_MODELS[newType];
      const canBeRequired = newType !== QUESTION_TYPES.statement;
      const newFields = fields.map((field, index) => {
        if (index === questionInd) {
          if (questionModel?.properties?.choices) {
            return {
              ...questionModel,
              tempId,
              title: field.title,
              properties: {
                ...questionModel.properties,
                description: field?.properties?.description,
                choices: field?.properties?.choices || questionModel.properties.choices,
                allow_other_choice:
                  field?.properties?.allow_other_choice ||
                  questionModel.properties.allow_other_choice,
              },
              validations: canBeRequired
                ? {
                    required: field?.validations?.required || questionModel.validations.required,
                  }
                : {},
            };
          }
          return {
            ...questionModel,
            tempId,
            title: field.title,
            properties: {
              description: field?.properties?.description,
            },
            validations: canBeRequired
              ? {
                  required: field?.validations?.required || questionModel.validations.required,
                }
              : {},
          };
        }

        return field;
      });

      const newState = {
        ...state,
        builder: {
          ...state.builder,
          past: newPast,
          present: {
            ...state.builder.present,
            fields: newFields,
          },
        },
      };
      return newState;
    },

    clearBuilder(state) {
      state.builder.past = [];
      state.builder.future = [];
      state.builder.present = { thankyou_screen: null, welcome_screen: null, fields: [] };
      state.isLoaded = false;
      state.isLoading = false;
    },
    setBuilderError(state, { payload: error }) {
      state.builder.error = error;
    },
  },
  extraReducers: {
    [loadQuestionnairePreviewUrl.pending]: state => {
      state.isLoading = true;
    },
    [loadQuestionnairePreviewUrl.fulfilled]: (state, { payload: previewUrl }) => {
      state.isLoading = false;
      state.isLoaded = true;

      state.previewUrl = previewUrl;
    },
    [loadQuestionnairePreviewUrl.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [loadQuestionnaireStructure.pending]: state => {
      state.isLoading = true;
    },
    [loadQuestionnaireStructure.fulfilled]: (state, { payload: structure }) => {
      state.isLoading = false;
      state.isLoaded = true;

      const { welcome_screens, thankyou_screens, fields } = structure;
      const welcomeScreenBlock = welcome_screens?.[0];
      const thankYouScreenBlock = thankyou_screens?.[0];

      const fieldsWithCustomTypes = fields.map(field => {
        const isSingleChoiceType =
          field.type === QUESTION_TYPES.multipleChoice &&
          !field.properties.allow_multiple_selection;

        return {
          ...field,
          type: isSingleChoiceType ? QUESTION_TYPES.singleChoice : field.type,
        };
      });

      state.builder.past = [];
      state.builder.future = [];
      state.builder.present.thankyou_screen = thankYouScreenBlock;
      state.builder.present.welcome_screen = welcomeScreenBlock;
      state.builder.present.fields = fieldsWithCustomTypes;
      state.structure = structure;
    },
    [loadQuestionnaireStructure.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [updateQuestionnaireStructure.pending]: state => {
      state.isLoading = true;
    },
    [updateQuestionnaireStructure.fulfilled]: state => {
      state.isLoading = false;
      state.isLoaded = true;
      state.builder.past = [];
      state.builder.future = [];
    },
    [updateQuestionnaireStructure.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
  },
});

const { reducer, actions } = questionnaireSlice;
export const {
  remove,
  add,
  undo,
  update,
  initState,
  moveFields,
  addChoice,
  removeChoice,
  addDescription,
  removeDescription,
  changeQuestionType,
  clearBuilder,
  setBuilderError,
} = actions;
export default reducer;
