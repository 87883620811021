import React, { useCallback } from 'react';
import cn from 'classnames/bind';

import Logo from 'ui/Logo';

import useTheme from 'hooks/useTheme';
import NextLink from 'components/NextLink';
import { RoutesRFP } from 'config/routes';
import styles from './styles.styl';

const cx = cn.bind(styles);

const renderLink = (mod, props) => (
  <NextLink className={cx('RFPFooter__link', `RFPFooter__link--${mod}`)} {...props} />
);

const RfpFooter = () => {
  const {
    theme: {
      palette: { main, footer },
    },
  } = useTheme();
  const theme = footer || main;

  const NextLinkPalette = useCallback(props => renderLink(theme, props), [theme]);

  const currentYear = new Date().getFullYear();

  return (
    <footer className={cx('RFPFooter', `RFPFooter--${theme}`)}>
      <NextLink route={RoutesRFP.Home}>
        <Logo className={cx(`RFPFooter__logo--${theme}`)} />
      </NextLink>
      <section className={cx(`RFPFooter__links`)}>
        <NextLinkPalette route={RoutesRFP.JobSeekerTerms}>Job-seeker terms</NextLinkPalette>
        <NextLinkPalette href="/privacy-policy">Privacy Policy</NextLinkPalette>
        <NextLinkPalette route={RoutesRFP.TermsOfService}>Terms of Service</NextLinkPalette>
        <NextLinkPalette route={RoutesRFP.TermsOfUse}>Terms of use</NextLinkPalette>
        <NextLinkPalette route={RoutesRFP.PricingPage}>Pricing</NextLinkPalette>
      </section>
      <p className={cx('RFPFooter__copyright')}>{currentYear}. 80 level. All rights reserved</p>
    </footer>
  );
};

export default RfpFooter;
