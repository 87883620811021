import { required, validateForm, url } from 'redux-form-validators';

export const validateJob = validateForm({
  title: required(),
  external_link: [
    value => {
      if (value && value.trim() !== '') {
        return url({
          message: 'Enter a valid URL that starts with https://',
          protocols: ['https'],
        })(value);
      }
      return undefined; // Return undefined to indicate the field is valid when empty
    },
  ],
});
