import types from './types';

const initialState = {
  error: {},
  items: [],
  offset: 0,
  isLoaded: false,
  isLoading: false,
};

export default function createPortfolioReducer(
  state = initialState,
  { error, offset, portfolios, type, domain, isFavorite, ...loadStatuses },
) {
  switch (type) {
    case types.LOAD_REQUEST:
    case types.LOAD_SUCCESS:
      return { ...state, ...loadStatuses };

    case types.LOAD_FAILURE:
      return { ...state, error, ...loadStatuses };

    case types.SET_OFFSET: {
      return { ...state, offset, ...loadStatuses };
    }

    case types.SET_PORTFOLIOS: {
      return { ...state, items: [...state.items, ...portfolios] };
    }

    case types.CLEAR_PORTFOLIOS: {
      return { ...state, items: portfolios, offset };
    }

    case types.SET_FAVORITE: {
      const updatedItems = state.items.map(item => {
        if (item.portfolio.domain === domain) {
          return {
            ...item,
            is_favorite: isFavorite,
          };
        }
        return item;
      });
      return { ...state, items: updatedItems };
    }

    default:
      return state;
  }
}
