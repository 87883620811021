import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  items: [],
  responses: {
    isLoading: false,
    isLoaded: false,
    error: null,
    itemsById: {},
  },
  questionsById: {},
};

const DEFAULT_NEW_QUESTIONNAIRE = '80 Level RFP Contract Hire';

export const createQuestionnaire = createAsyncThunk(
  'questionnaires/create',
  async (_, { getState, extra: httpService }) => {
    const { items } = getState().rfpReducer.company.questionnaires;
    const { id } = items.find(({ title }) => title === DEFAULT_NEW_QUESTIONNAIRE) || {};

    const response = await httpService.post({
      url: 'rfp/questionnaire/create',
      data: { title: 'Custom Questionnaire', copy_from: id },
    });

    const createdQuestionnaire = response.results;

    return [...items, createdQuestionnaire];
  },
);

export const loadQuestionnaires = createAsyncThunk(
  'questionnaires/load',
  async (_, { extra: httpService }) => {
    const response = await httpService.get({
      url: 'rfp/company_questionnaires',
    });

    return response.results;
  },
);

export const sendQuestionnaires = createAsyncThunk(
  'questionnaires/send',
  async ({ domain, questionnaires }, { extra: httpService }) => {
    await httpService.post({
      url: `rfp/questionnaire/send/${domain}`,
      data: { questionnaires },
    });
  },
);

export const loadQuestionnaireResponses = createAsyncThunk(
  'questionnaires/responses/load',
  async (id, { extra: httpService }) => {
    const { results } = await httpService.get({
      url: `rfp/questionnaire/${id}/responses_json`,
      headers: { 'Content-Type': 'application/json' },
    });
    return results || { questions: [], responses: [] };
  },
);

const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  extraReducers: {
    [createQuestionnaire.pending]: state => {
      state.isLoading = true;
    },
    [createQuestionnaire.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.items = payload;
    },
    [createQuestionnaire.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },

    [loadQuestionnaires.pending]: state => {
      state.isLoading = true;
    },
    [loadQuestionnaires.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.items = payload;
    },
    [loadQuestionnaires.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },

    [sendQuestionnaires.pending]: state => {
      state.isLoading = true;
    },
    [sendQuestionnaires.fulfilled]: (
      state,
      {
        meta: {
          arg: { questionnaires },
        },
      },
    ) => {
      state.isLoading = false;
      state.isLoaded = true;
      const { items } = state;
      const updatedItems = items.map(questionnaire => {
        const { id } = questionnaire;
        if (id === questionnaires[0]) {
          const updatedQuestionnaire = {
            ...questionnaire,
            responses_stat: questionnaire.responses_stat
              ? {
                  ...questionnaire.responses_stat,
                  sent: questionnaire.responses_stat.sent + 1,
                }
              : { sent: 1 },
          };

          return updatedQuestionnaire;
        }

        return questionnaire;
      });
      state.items = updatedItems;
    },
    [sendQuestionnaires.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },

    [loadQuestionnaireResponses.pending]: state => {
      state.responses.isLoading = true;
    },
    [loadQuestionnaireResponses.fulfilled]: (state, { payload, meta: { arg: id } }) => {
      state.responses.isLoading = false;
      state.responses.isLoaded = true;
      state.responses.itemsById[id] = payload.responses;
      state.questionsById[id] = payload.questions;
    },
    [loadQuestionnaireResponses.rejected]: (state, { error }) => {
      state.responses.isLoading = false;
      state.responses.isLoaded = false;
      state.responses.error = error;
    },
  },
});

const { reducer } = questionnairesSlice;

export default reducer;
