import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

const FormError = ({ className, message }) => (
  <div className={cx(className, 'FormError')}>
    <img
      alt="error-mark"
      src="/static/images/rfp/error-mark.svg"
      className={cx('FormError__icon')}
    />
    <span className={cx('FormError__message')}>{message}</span>
  </div>
);

FormError.defaultProps = {
  className: '',
  message: '',
};

FormError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default FormError;
