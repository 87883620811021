import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'spin.js';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { formDataSelector } from 'state/profile/selectors';
import CrossIcon from 'features/rfp/ui/icons/CrossIcon';

import bemCN from 'utils/bemCN';
import styles from './styles.styl';

const el = bemCN(styles)('FeedbackButton');

const spinnerOpts = {
  lines: 10, // The number of lines to draw
  length: 1, // The length of each line
  width: 3, // The line thickness
  radius: 8, // The radius of the inner circle
  scale: 0.7, // Scales overall size of the spinner
  corners: 0.5, // Corner roundness (0..1)
  speed: 0.6, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#ffffff', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  zIndex: 2000000000, // The z-index (defaults to 2e9)
  className: 'spinner', // The CSS class to assign to the spinner
  position: 'absolute', // Element positioning
};

const FeedbackIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 0H0V9L6 15V12H14C15.1055 12 16 11.1045 16 10V2C16 0.895508 15.1055 0 14 0ZM4 7C4.55273 7 5 6.55225 5 6C5 5.44775 4.55273 5 4 5C3.82617 5 3.66211 5.04468 3.51758 5.12305C3.43164 5.17041 3.35352 5.22998 3.28516 5.2998C3.10938 5.48022 3 5.72729 3 6C3 6.55225 3.44727 7 4 7ZM8 7C8.55273 7 9 6.55225 9 6C9 5.69946 8.86719 5.42993 8.6582 5.24658C8.48242 5.09302 8.25195 5 8 5C7.44727 5 7 5.44775 7 6C7 6.22729 7.07617 6.43701 7.20312 6.60498C7.3125 6.75 7.46094 6.86401 7.63477 6.93164C7.74805 6.97583 7.87109 7 8 7ZM12.6934 6.72095C12.5137 6.8938 12.2695 7 12 7C11.9414 7 11.8828 6.99487 11.8262 6.98511C11.6641 6.95654 11.5137 6.88818 11.3867 6.79004C11.1504 6.60693 11 6.32104 11 6C11 5.56738 11.2734 5.19873 11.6582 5.05957C11.7168 5.03833 11.7773 5.02246 11.8398 5.01245C11.8926 5.00439 11.9453 5 12 5C12.5527 5 13 5.44775 13 6C13 6.2832 12.8828 6.53906 12.6934 6.72095Z"
      fill="white"
    />
  </svg>
);

const FeedbackButton = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const spinnerContainerRef = useRef(null);
  const spinnerRef = useRef(null);
  useEffect(() => {
    if (spinnerContainerRef.current && isLoading) {
      spinnerRef.current = new Spinner(spinnerOpts).spin(spinnerContainerRef.current);
    }
  }, [isLoading]);

  const typeformRef = useRef(null);
  const typeformPopupRef = useRef(null);
  const router = useRouter();
  const { email = '' } = useSelector(formDataSelector);
  const typeformUrlRef = useRef();

  useEffect(() => {
    if (!typeformRef.current || !window || !window.typeformEmbed) {
      return;
    }
    const typeformEmbed = window.typeformEmbed;

    const currentUrl = location ? new URL(router.asPath, location.origin) : '';
    const typeformUrl = `https://form.typeform.com/to/kE7bkyKr?typeform-medium=embed-snippet#email=${email}&url=${currentUrl}`;
    typeformUrlRef.current = typeformUrl;

    typeformPopupRef.current = typeformEmbed.makePopup(typeformUrl, {
      mode: 'side_panel',
      container: typeformRef.current,
      hideScrollbars: true,
      autoClose: 5,
      onReady: () => {
        setLoading(false);
        spinnerRef.current.stop();
      },
      onClose: () => {
        setOpen(false);
      },
    });
  }, [email, router.asPath]);

  const handleFeedbackClick = e => {
    if (!typeformPopupRef.current) {
      return;
    }

    e.preventDefault();

    typeformPopupRef.current.open();
    if (!isOpen) {
      setLoading(true);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div ref={typeformRef} className={el('form-container')}>
      <a
        className={el()}
        href={typeformUrlRef.current}
        target="_blank"
        rel="noreferrer"
        onClick={handleFeedbackClick}
      >
        <span ref={spinnerContainerRef} className={el('icon')}>
          {!isLoading && !isOpen && <FeedbackIcon />}
          {!isLoading && isOpen && <CrossIcon fill="white" />}
        </span>
        <span className={el('text')}>Feedback</span>
      </a>
    </div>
  );
};

export default FeedbackButton;
