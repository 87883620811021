import React from 'react';

import { Field } from 'components/FinalForm';
import CheckboxRFP from 'features/registration/ui/CheckboxRFP';

const CheckboxField = ({ name, title, ...props }) => (
  <Field name={name} title={title} type="checkbox" component={CheckboxRFP} {...props} />
);

export default CheckboxField;
