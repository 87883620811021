import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import RegistrationContext from '../../context/RegistrationContext';
import Routes, { Router } from 'utils/routes';
import { RoutesRFP } from 'config/routes';
import useAuth from 'features/auth/hooks/useAuth';
import { Roles } from 'features/auth/utils/roles';
import { logout } from 'features/auth/state/auth/actions';
// import useProfile from 'hooks/useProfile';
import useInviteCode from 'features/registration/hooks/useInviteCode';

const RegistrationProvider = ({ children }) => {
  const { query = {}, asPath } = useRouter();
  const { route = {} } = Routes.findAndGetUrls(asPath);
  const { isAuthenticated, currentRole, isLoaded: isAuthLoaded } = useAuth();
  // const { formData, isLoaded: isLoadedFormData } = useProfile();

  const registrationPathRegEx = /\/registration/i;
  const inRegistrationFlow = useMemo(() => registrationPathRegEx.test(asPath), [
    asPath,
    registrationPathRegEx,
  ]);

  useEffect(() => {
    if (!inRegistrationFlow) return;
    if (!isAuthLoaded) return;

    if (route.name !== RoutesRFP.SignUp.name && !isAuthenticated) {
      window.location.replace(RoutesRFP.Home.pattern);
    }

    // if (isAuthenticated && currentRole !== Roles.Default) {
    //   closeRegistration();
    // }
  }, [
    closeRegistration,
    currentRole,
    inRegistrationFlow,
    isAuthLoaded,
    isAuthenticated,
    query,
    route.name,
  ]);

  // const isArtistAndSkipLastStep = useMemo(() => {
  //   if (!isLoadedFormData) return false;

  //   if (isAuthenticated) {
  //     if (currentRole === Roles.Artist) {
  //       const { country, name, surname, position } = formData;
  //       return !country || !name || !surname || !position;
  //     }
  //     return false;
  //   }
  //   return false;
  // }, [currentRole, formData, isAuthenticated, isLoadedFormData]);

  // useEffect(() => {
  //   if (
  //     isArtistAndSkipLastStep &&
  //     route.name !== RoutesRFP.JobSeekerTerms.name &&
  //     route.name !== RoutesRFP.TermsOfUse.name &&
  //     route.name !== RoutesRFP.ArtistInformation.name
  //   ) {
  //     Router.replaceRoute(RoutesRFP.ArtistInformation.name, { redirect: route.name });
  //   }
  // }, [isArtistAndSkipLastStep, route.name]);

  // Logic for redirect to Invite Code Step

  const { isRequested: isInviteCodeRequested } = useInviteCode();

  const userAtInviteCodeStep = useMemo(
    () => isAuthenticated && isInviteCodeRequested && currentRole === Roles.Default,
    [currentRole, isAuthenticated, isInviteCodeRequested],
  );

  const needRedirectToInviteCodeStep = useMemo(
    () => userAtInviteCodeStep && route.name !== RoutesRFP.ArtistInviteCode.name,
    [route.name, userAtInviteCodeStep],
  );

  useEffect(() => {
    if (needRedirectToInviteCodeStep) {
      Router.replaceRoute(RoutesRFP.ArtistInviteCode.name, { redirect: route.name });
    }
  }, [needRedirectToInviteCodeStep, route.name]);

  const openRegistration = useCallback(
    (role, src) => {
      return Router.pushRoute(RoutesRFP.SignUp.name, {
        type: 'sign-up',
        redirect: inRegistrationFlow ? null : route.name,
        role: role ? role.toLowerCase() : null,
        from: src || null,
        ...query,
      });
    },
    [inRegistrationFlow, query, route.name],
  );

  const closeRegistration = useCallback(() => {
    const redirectRoute = Routes.findByName(query.redirect || RoutesRFP.Home.name);

    const newQuery = { ...query, redirect: null, type: null, role: null };
    const asHref = redirectRoute ? redirectRoute.getAs(newQuery) : null;
    window.location.replace(asHref);
  }, [query]);

  const dispatch = useDispatch();
  const handleLogout = useCallback(async () => {
    dispatch(logout());
    return closeRegistration();
  }, [closeRegistration, dispatch]);

  const contextData = {
    openRegistration,
    closeRegistration,
    handleLogout,
  };

  return (
    <RegistrationContext.Provider value={contextData}>{children()}</RegistrationContext.Provider>
  );
};

RegistrationProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export default RegistrationProvider;
