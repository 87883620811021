import React, { useCallback } from 'react';
import PropsTypes from 'prop-types';

import Button from 'features/rfp/ui/Button';
import { Router } from 'utils/routes';
import { RoutesRFP } from 'config/routes';
import useAnalytics from 'hooks/useAnalytics';

import bemCN from 'utils/bemCN';
import styles from './styles.styl';

const el = bemCN(styles)('QuestionnaireSection');

const QuestionnaireSection = ({ request_id, id, parent }) => {
  const { elementClick } = useAnalytics();

  const goToEditor = useCallback(() => {
    Router.pushRoute(RoutesRFP.QuestionnaireEditor.name, { id }).then(() => {
      const timeoutId = setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
        });
        clearTimeout(timeoutId);
      }, 10);
    });
  }, [id]);

  const handleCustomizeClick = () => {
    goToEditor();
    elementClick(`80lv_job_${parent}_customize-questionnaire`, undefined, { job_id: id });
  };

  return (
    <div className={el()}>
      <div
        className={el('item', { disabled: !request_id })}
        onClick={request_id ? handleCustomizeClick : () => {}}
      >
        <div className={el('item-title')}>Questionnaire</div>
      </div>

      <div className={el('description')}>
        The questionnaire is an important pre-interview screening component of every Job. The
        questionnaire can include any and all job-related questions you want candidates to answer,
        and must be completed by every job-seeker applying to the Job. The result is a faster, more
        efficient recruiting process.
        <br />
        {request_id && (
          <Button onClick={handleCustomizeClick} className={el('button')} isUppercase>
            Customize
          </Button>
        )}
        {!request_id && (
          <p className={el('no-questionnaire-tip')}>
            It takes 1-2 minutes to create a form. Refresh to unlock the preview
          </p>
        )}
      </div>
    </div>
  );
};

QuestionnaireSection.propTypes = {
  request_id: PropsTypes.number,
  parent: PropsTypes.string,
  id: PropsTypes.string,
};

export default QuestionnaireSection;
