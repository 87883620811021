/* eslint-disable no-unused-vars */
import useAuth from 'features/auth/hooks/useAuth';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update, create, load } from 'state/company/actions';
import { formDataSelector } from 'state/company/selectors';

export default function useCompany() {
  const dispatch = useDispatch();
  const { isLoaded, isLoading } = useSelector(state => state.company);
  const { token } = useAuth();
  const formData = useSelector(formDataSelector);

  const updateCompany = useCallback(data => dispatch(update(data)), [dispatch]);

  const createCompany = useCallback(data => dispatch(create(data)), [dispatch]);

  useEffect(() => {
    token && !isLoaded && dispatch(load(token));
  }, [dispatch, isLoaded, token]);

  const hasCompanyInfo = useMemo(() => Object.values(formData).filter(Boolean).length > 0, [
    formData,
  ]);

  return {
    isLoading,
    formData,
    updateCompany,
    createCompany,
    hasCompanyInfo,
  };
}
