import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Check from '../icons/Check';
import styles from './styles.styl';
import bemCN from 'utils/bemCN';

const el = bemCN(styles)('CheckboxRFP');

const CheckboxRFP = ({
  className = '',
  inputClassName,
  titleClassName,
  fillerClassName,
  onChange = () => {},
  title = null,
  ...restProps
}) => (
  <label className={cn(el(), className)}>
    <input
      type="checkbox"
      className={cn(el('input'), inputClassName)}
      onChange={onChange}
      {...restProps}
    />
    <div className={cn(el('filler'), fillerClassName)}>
      <Check className={el('icon')} />
    </div>
    <span className={cn(el('title'), titleClassName)}>{title}</span>
  </label>
);

CheckboxRFP.propTypes = {
  title: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  fillerClassName: PropTypes.string,
};

export default CheckboxRFP;
