const imageBasePath = '/static/images/rfp/instructions/ar';

export default {
  title: 'ArtStation',
  link: 'https://www.artstation.com/',
  structure: [
    [
      {
        type: 'text',
        content: '2. Click your account icon in the upper right to open your profile settings',
      },
      {
        type: 'image',
        content: { src: `${imageBasePath}/1.png`, alt: 'About tab' },
      },
    ],
    [
      {
        type: 'text',
        content: '3. Choose the Resume tab',
      },
      {
        type: 'image',
        content: { src: `${imageBasePath}/2.png`, alt: 'About tab' },
      },
    ],
    [
      { type: 'text', content: '4. Paste your profile link in the Summary field' },
      { type: 'profileLink' },
    ],
    { type: 'text', content: '5. Click Save' },
  ],
};
