import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const initialState = {
  error: '',
  isLoaded: false,
  isLoading: false,
  isRequested: false,
  status: 'not_requested',
  retryTimestamp: null,
};

export const requestInviteCode = createAsyncThunk(
  'inviteCode/request',
  async ({ token, portfolioUrl }, { extra: httpService }) => {
    const response = await httpService.post({
      headers: { token },
      data: { portfolio_url: portfolioUrl },
      url: '/invite/request',
    });

    return response;
  },
);

export const checkInviteCodeRequest = createAsyncThunk(
  'inviteCode/check',
  async (token, { extra: httpService }) => {
    const { results } = await httpService.get({
      headers: { token },
      url: '/invite/check_request',
    });
    const isRequested = !isEmpty(results);
    const retryTimestamp = results.retry_after * 1000;
    return { isRequested, status: results.status, retryTimestamp };
  },
);

export const activateInviteCode = createAsyncThunk(
  'inviteCode/activate',
  async (code, { extra: httpService }) => {
    const { results } = await httpService.get({
      url: `/invite/activate?code=${code}`,
    });
    const token = get(results, ['0', 'token']) || get(results, ['token']);
    return token;
  },
);

const inviteCodeSlice = createSlice({
  name: 'inviteCode',
  initialState,
  extraReducers: {
    [requestInviteCode.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [requestInviteCode.fulfilled]: state => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isRequested = true;
      state.status = 'not_processed';
    },
    [requestInviteCode.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = error.message;
    },
    [checkInviteCodeRequest.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [checkInviteCodeRequest.fulfilled]: (
      state,
      { payload: { isRequested, retryTimestamp, status } },
    ) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isRequested = isRequested;
      state.status = status;
      state.retryTimestamp = retryTimestamp;
    },
    [checkInviteCodeRequest.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = error.message;
    },
    [activateInviteCode.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [activateInviteCode.fulfilled]: state => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    [activateInviteCode.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = error.message;
    },
  },
});

const { reducer } = inviteCodeSlice;

export default reducer;

export const inviteCodeSelector = ({ inviteCodeReducer }) => inviteCodeReducer;
