import React from 'react';
import PropTypes from 'prop-types';

const FieldError = ({ className, error }) => <div className={className}>{error}</div>;

FieldError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
};

export default FieldError;
