import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: {},
  isLoading: false,
  isLoaded: false,
  offset: 0,
  items: [],
  allIds: [],
  byIds: {},
  responses: {
    isLoading: false,
    isLoaded: false,
    error: null,
    itemsById: {},
  },
  questionsById: {},
};

export const createJob = createAsyncThunk(
  'job/createJob',
  async ({ data }, { extra: httpService }) => {
    const response = await httpService.post({
      url: '/rfp/jobs',
      data,
    });
    return response.results;
  },
);

export const loadJobs = createAsyncThunk('job/loadJobs', async (_, { extra: httpService }) => {
  const response = await httpService.get({
    url: 'rfp/company/jobs',
  });

  const payload = response.results.reduce(
    (accumulator, currentValue) => {
      accumulator.byIds[currentValue.id] = currentValue;
      accumulator.allIds.push(currentValue.id);
      return accumulator;
    },
    { byIds: {}, allIds: [] },
  );

  return payload;
});

export const loadJob = createAsyncThunk('job/loadJob', async ({ id }, { extra: httpService }) => {
  const response = await httpService.get({
    url: `/rfp/jobs/${id}`,
  });
  return response.results;
});

export const updateJob = createAsyncThunk(
  'job/updateJob',
  async ({ id, data }, { extra: httpService }) => {
    const response = await httpService.post({
      url: `/rfp/jobs/${id}`,
      data: data,
    });
    return response.results;
  },
);

export const sendJob = createAsyncThunk(
  'job/send',
  async ({ jobId, domain }, { extra: httpService }) => {
    const response = await httpService.post({
      url: `/rfp/jobs/request/artist`,
      data: {
        domain,
        job_id: jobId,
      },
    });
    return response.results;
  },
);

export const publishJob = createAsyncThunk('job/publish', async (id, { extra: httpService }) => {
  const response = await httpService.post({
    url: `/rfp/jobs/${id}/publish`,
  });
  return response.results;
});

export const loadJobsResponses = createAsyncThunk(
  'job/responses/load',
  async (id, { extra: httpService }) => {
    const { results } = await httpService.get({
      url: `rfp/jobs/${id}/responses`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return results || { questions: [], responses: [] };
  },
);

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  extraReducers: {
    [createJob.pending]: state => {
      state.isLoading = true;
    },
    [createJob.fulfilled]: (state, { payload: job }) => {
      state.isLoading = false;
      state.isLoaded = true;

      state.allIds = [...state.allIds, job.id];
      state.byIds[job.id] = job;
      state.items.push(job);
    },
    [createJob.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [loadJobs.pending]: state => {
      state.isLoading = true;
    },
    [loadJobs.fulfilled]: (state, { payload: { byIds, allIds } }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.allIds = allIds;
      // state.byIds = byIds;
      const items = allIds.map(id => byIds[id]);
      state.items = items;
    },
    [loadJobs.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [loadJob.pending]: state => {
      state.isLoading = true;
    },
    [loadJob.fulfilled]: (state, { payload: job }) => {
      state.isLoading = false;
      state.isLoaded = true;

      const jobData = state.byIds[job.id];
      if (jobData) {
        const newJob = { ...jobData, ...job };
        state.byIds[job.id] = newJob;
      } else {
        state.byIds[job.id] = job;
      }
    },
    [loadJob.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [updateJob.pending]: state => {
      state.isLoading = true;
    },
    [updateJob.fulfilled]: (state, { payload: newJob }) => {
      state.isLoading = false;
      state.isLoaded = true;

      state.byIds[newJob.id] = newJob;
      const newItems = state.items.map(job => {
        if (job.id === newJob.id) {
          return newJob;
        } else {
          return job;
        }
      });
      state.items = newItems;
    },
    [updateJob.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },

    [loadJobsResponses.pending]: state => {
      state.responses.isLoading = true;
    },
    [loadJobsResponses.fulfilled]: (state, { payload, meta: { arg: id } }) => {
      state.responses.isLoading = false;
      state.responses.isLoaded = true;
      state.responses.itemsById[id] = payload.responses;
      state.questionsById[id] = payload.questions;
    },
    [loadJobsResponses.rejected]: (state, { error }) => {
      state.responses.isLoading = false;
      state.responses.isLoaded = false;
      state.responses.error = error;
    },

    [publishJob.pending]: state => {
      state.isLoading = true;
    },
    [publishJob.fulfilled]: (state, { payload: job }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.byIds[job.id] = job;
    },
    [publishJob.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [sendJob.pending]: state => {
      state.isLoading = true;
    },
    [sendJob.fulfilled]: state => {
      state.isLoading = false;
    },
    [sendJob.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    },
  },
});

const { reducer } = jobsSlice;

export default reducer;
