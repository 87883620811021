import { combineReducers } from 'redux';
import landing from './modules/landing';
import pages from './modules/pages';
import blocks from './modules/blocks';
import sideMenu from './modules/spaces';
import assets from './modules/assets';
import articles from './modules/articles';

export default combineReducers({
  pages,
  assets,
  blocks,
  landing,
  articles,
  sideMenu,
});
