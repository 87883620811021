import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Form from 'components/Form';
import InputFile from 'components/InputFile';
import LocationInput from 'ui/LocationInput';
import CheckboxRFP from 'features/registration/ui/CheckboxRFP';
import ProfileForm from 'components/Forms/ProfileForm/ProfileForm';
import Button from 'features/rfp/ui/Button';
import AvatarProfile from 'features/rfp/ui/AvatarProfile';
import FormError from 'features/rfp/ui/FormError';
import ModernInput from 'features/rfp/ui/ModernInput';
import BaseModal from 'features/rfp/ui/BaseModal';
import StudioSettingsForm from './StudioSettingsForm';
import CompanySettingsForm from './CompanySettingsForm';

import { validateArtistInfo } from 'components/Forms/ProfileForm/validations';
import { getSubmitError } from 'utils/form';
import useProfile from 'hooks/useProfile';
import useAuth from '../../../auth/hooks/useAuth';
import { Roles } from '../../../auth/utils/roles';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

export const profileSettingsPopupEl = bemCN(styles)('ProfileSettingsPopup');

const ProfileSettingsPopup = ({ closeModal }) => {
  const { updateProfile } = useProfile();

  const submit = useCallback(
    async data => {
      const { error } = await updateProfile(data);
      const { message } = error || {};

      return getSubmitError(message);
    },
    [updateProfile],
  );

  const isVerticalSmallTablet = useSelector(({ responsive }) => responsive.isVerticalSmallTablet);

  const { currentRole } = useAuth();
  const isJobSeeker = useMemo(() => currentRole === Roles.Artist || currentRole === Roles.Studio, [
    currentRole,
  ]);
  const showStatusField = isJobSeeker;

  const isStudio = currentRole === Roles.Studio;

  if (isStudio) {
    return (
      <BaseModal
        closeModal={closeModal}
        className={profileSettingsPopupEl()}
        contentClassName={profileSettingsPopupEl('modal-content')}
        content={<StudioSettingsForm closeModal={closeModal} />}
      />
    );
  }

  const isCompany = currentRole === Roles.Company;
  if (isCompany) {
    return (
      <BaseModal
        closeModal={closeModal}
        className={profileSettingsPopupEl()}
        contentClassName={profileSettingsPopupEl('modal-content')}
        content={<CompanySettingsForm closeModal={closeModal} />}
      />
    );
  }

  return (
    <BaseModal
      closeModal={closeModal}
      className={profileSettingsPopupEl()}
      contentClassName={profileSettingsPopupEl('modal-content')}
      content={
        <ProfileForm
          validate={validateArtistInfo}
          onSubmit={submit}
          render={({
            form,
            UploadAvatarField,
            NameField,
            SurnameField,
            PositionField,
            LocationField,
            EmailField,
            RFPProfileShowStatusField,
            EmailAgreementField,
            handleSubmit,
            submitError,
          }) => {
            const {
              dirty,
              valid,
              form: { reset },
            } = form;

            const resetForm = e => {
              e.preventDefault();
              reset(e);
            };

            const handleSubmitForm = async e => {
              await handleSubmit(e);
              closeModal();
            };

            const cancelButton = (
              <Button theme="black" isOutlined isUppercase onClick={dirty ? resetForm : closeModal}>
                {dirty ? 'Cancel' : 'Close'}
              </Button>
            );

            const saveButton = (
              <Button
                theme="black"
                isUppercase
                onClick={handleSubmitForm}
                disabled={!dirty || !valid}
              >
                Save
              </Button>
            );

            return (
              <Form className={profileSettingsPopupEl('form')}>
                <div className={profileSettingsPopupEl('action', { header: true })}>
                  {!isVerticalSmallTablet && cancelButton}
                  <span className={profileSettingsPopupEl('actionText')}>
                    {!isVerticalSmallTablet ? 'Settings' : 'Profile'}
                  </span>
                  {!isVerticalSmallTablet && saveButton}
                </div>
                <div className={profileSettingsPopupEl('content')}>
                  <div className={profileSettingsPopupEl('upload-image')}>
                    <UploadAvatarField
                      className={profileSettingsPopupEl('avatar')}
                      component={InputFile}
                      cover={AvatarProfile}
                    />
                  </div>
                  <div className={profileSettingsPopupEl('fields')}>
                    <NameField
                      component={ModernInput}
                      label="First name"
                      className={profileSettingsPopupEl('input')}
                      focusClassName={profileSettingsPopupEl('inputFocus')}
                      errorClassName={profileSettingsPopupEl('errorInput')}
                      placeholder="John"
                    />
                    <SurnameField
                      component={ModernInput}
                      label="Last name"
                      className={profileSettingsPopupEl('input')}
                      focusClassName={profileSettingsPopupEl('inputFocus')}
                      errorClassName={profileSettingsPopupEl('errorInput')}
                      placeholder="Doe"
                    />
                    <LocationField
                      label="Location"
                      component={LocationInput}
                      FieldComponent={ModernInput}
                      className={profileSettingsPopupEl('input-wrapper')}
                      inputClassName={profileSettingsPopupEl('input')}
                      focusClassName={profileSettingsPopupEl('inputFocus')}
                      errorClassName={profileSettingsPopupEl('errorInput')}
                    />
                    <PositionField
                      component={ModernInput}
                      label="Current position"
                      className={profileSettingsPopupEl('input')}
                      focusClassName={profileSettingsPopupEl('inputFocus')}
                      errorClassName={profileSettingsPopupEl('errorInput')}
                      placeholder="VFX Artist"
                    />
                    <EmailField
                      component={ModernInput}
                      label="Email"
                      className={profileSettingsPopupEl('input')}
                      focusClassName={profileSettingsPopupEl('inputFocus')}
                      errorClassName={profileSettingsPopupEl('errorInput')}
                      placeholder="example@gmail.com"
                      disabled
                    />
                    <div className={profileSettingsPopupEl('checkbox-wrapper')}>
                      <div className={profileSettingsPopupEl('checkbox-empty-label')} />
                      <EmailAgreementField
                        component={CheckboxRFP}
                        className={profileSettingsPopupEl('checkbox')}
                      />
                    </div>
                    {showStatusField && (
                      <div className={profileSettingsPopupEl('checkbox-wrapper')}>
                        <div className={profileSettingsPopupEl('checkbox-empty-label')} />
                        <RFPProfileShowStatusField
                          component={CheckboxRFP}
                          className={profileSettingsPopupEl('checkbox')}
                        />
                      </div>
                    )}
                  </div>

                  {submitError && (
                    <FormError
                      message={submitError}
                      className={profileSettingsPopupEl('errorForm')}
                    />
                  )}
                </div>
                {isVerticalSmallTablet && (
                  <div className={profileSettingsPopupEl('action', { footer: true })}>
                    {cancelButton}
                    {saveButton}
                  </div>
                )}
              </Form>
            );
          }}
        />
      }
    />
  );
};

ProfileSettingsPopup.defaultProps = {
  closeModal: () => {},
};

ProfileSettingsPopup.propTypes = {
  closeModal: PropTypes.func,
};

export default memo(ProfileSettingsPopup);
