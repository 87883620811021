import React from 'react';
import useModal from 'hooks/useModal';
import JobCreationContext from '../../context/JobCreationContext';
import JobCreationModal from '../JobCreationModal';
import Routes, { Router } from 'utils/routes';
import { useRouter } from 'next/router';

const JobCreationProvider = ({ children }) => {
  const {
    Modal: JobCreationModalWrapper,
    ref: jobCreationModalRef,
    isOpen: isJobCreationModalOpen,
    openModal: openJobCreationModal,
    closeModal: closeJobCreationModal,
  } = useModal();

  const { asPath, query } = useRouter();
  const { route } = Routes.findAndGetUrls(asPath);

  const handleJobCreationModalClose = async () => {
    const params = { ...query, action: undefined, category: undefined };
    await Router.pushRoute(route.name, params);
    closeJobCreationModal();
  };

  const contextData = {
    openJobCreationModal,
    closeJobCreationModal: handleJobCreationModalClose,
    isJobCreationModalOpen,
  };
  return (
    <JobCreationContext.Provider value={contextData}>
      <>
        {children}
        <div ref={jobCreationModalRef}>
          {isJobCreationModalOpen && (
            <JobCreationModalWrapper>
              <JobCreationModal onClose={handleJobCreationModalClose} />
            </JobCreationModalWrapper>
          )}
        </div>
      </>
    </JobCreationContext.Provider>
  );
};

export default JobCreationProvider;
