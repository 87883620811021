import React from 'react';
import PropTypes from 'prop-types';
import { Field as FinalFormField } from 'react-final-form';

import FieldError from './FieldError';

const getError = (touched, error, active) => (touched && error && !active ? error : '');

const identity = value => value;

const Field = ({
  name,
  errorClassName,
  type,
  onChange = fn => fn,
  component: Component,
  showError = true,
  ...props
}) => (
  <FinalFormField type={type} name={name} parse={identity}>
    {({ input: { onFocus, ...input }, meta: { touched, error, active } }) => {
      return (
        <>
          <Component
            type={type}
            name={name}
            {...input}
            error={getError(touched, error, active)}
            onFocus={props.onFocus ? props.onFocus : onFocus}
            onChange={(e, actionTypes) => {
              if (
                actionTypes &&
                (actionTypes.action === 'clear' || actionTypes.action === 'remove-value')
              ) {
                input.onBlur(e);
              }
              return onChange(input.onChange)(e);
            }}
            {...props}
          />
          {showError && touched && error && <FieldError className={errorClassName} error={error} />}
        </>
      );
    }}
  </FinalFormField>
);

Field.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  component: PropTypes.elementType,
  errorClassName: PropTypes.string,
};

export default Field;
