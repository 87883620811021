import React from 'react';
import cn from 'classnames';

import { Field } from 'components/FinalForm';
import ModernInput from 'features/rfp/ui/ModernInput';
import { profileSettingsPopupEl as el } from '../ProfileSettingsPopup';

const InputField = ({ name, label, className, ...props }) => (
  <Field
    className={cn(el('input'), className)}
    focusClassName={el('inputFocus')}
    errorClassName={el('errorInput')}
    name={name}
    label={label}
    component={ModernInput}
    {...props}
  />
);

export default InputField;
