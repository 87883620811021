import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';

import Heading from 'components/Heading';
import styles from './styles.styl';
// TODO: add bemCN
const Toast = ({ type, title, body }) => (
  <div className={styles.ToastContent}>
    <div className={cn(styles.ToastContent__header, styles[`ToastContent__header--${type}`])}>
      <Heading level={3}>{title}</Heading>
    </div>
    <div className={styles.ToastContent__body}>{body}</div>
  </div>
);

Toast.propTypes = {
  body: PropTypes.node,
  title: PropTypes.node,
  type: PropTypes.oneOf(['success', 'error']),
};

export default Toast;
