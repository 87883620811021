import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ className = '', ...props }) => (
  <svg
    className={className}
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4L3 6L8 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string,
};

export default Check;
