import React from 'react';

const LogoIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 16" fill="currentColor" {...props}>
    <defs />
    <path d="M65.9 0h-4.7c-.2 0-.4.2-.4.4v10.3c0 .3-.2.5-.5.5h-4a.4.4 0 01-.4-.5V.4c0-.2-.2-.4-.4-.4h-4.7c-.3 0-.5.2-.5.4V11c0 .2.1.4.3.5l5 4.4.7.2h4.1c.2 0 .4 0 .6-.2l5.1-4.4c.2-.1.3-.3.3-.5V.4c0-.2-.3-.4-.5-.4zM49.1 11.2h-9.5a.4.4 0 01-.4-.5V.4c0-.2-.2-.4-.5-.4H34c-.2 0-.4.2-.4.4v7.8c0 4.3 3.5 7.8 7.8 7.8h7.7c.3 0 .5-.2.5-.4v-4c0-.2-.2-.4-.5-.4zM28 0h-6.4a4.8 4.8 0 00-4.8 4.8v6.4c0 2.6 2.1 4.8 4.8 4.8H28c2.6 0 4.8-2.2 4.8-4.8V4.8C32.8 2.2 30.6 0 28 0zm0 10.7c0 .3-.2.5-.5.5H22a.4.4 0 01-.4-.5V5.3c0-.3.2-.5.4-.5h5.5c.3 0 .5.2.5.5v5.4z" />
    <path d="M24.9 6.3A1.3 1.3 0 0023 8l1.7 1.6A1.3 1.3 0 0026.5 8L25 6.3zM13.8 7.9a3.2 3.2 0 002.2-3C16 2.1 13.8 0 11.2 0H4.8A4.8 4.8 0 000 4.8c0 1.4 1 2.6 2.2 3 .2.1.2.4 0 .5A3.7 3.7 0 000 11.6C0 15 3 16 5.6 16h4.8c2.6 0 5.6-1 5.6-4.4 0-1.5-.9-2.8-2.2-3.3a.2.2 0 010-.4zm-3.3 2.7a.3.3 0 010 .6h-5a.3.3 0 010-.6 2.7 2.7 0 000-5.2.3.3 0 010-.6h5a.3.3 0 010 .6 2.7 2.7 0 000 5.2z" />
  </svg>
);

export default LogoIcon;
