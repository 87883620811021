import { login } from 'features/auth/state/auth/actions';
import { setToken } from 'features/auth/utils/auth';
import types from './types';

const loadStart = () => ({
  type: types.LOAD_REQUEST,
  isLoaded: false,
  isLoading: true,
});

const loadSuccess = () => ({
  type: types.LOAD_REQUEST,
  isLoading: false,
  isLoaded: true,
});

const setFormData = formData => ({
  type: types.SET_FORM_DATA,
  formData,
});

const loadFail = error => ({
  type: types.LOAD_FAILURE,
  isLoading: false,
  isLoaded: false,
  error,
});

export const load = () => (dispatch, _, httpService) => {
  dispatch(loadStart());
  return httpService
    .get({ url: '/company/get' })
    .then(({ results }) => {
      dispatch(setFormData(results));
      dispatch(loadSuccess());
    })
    .catch(error => dispatch(loadFail(error)));
};

const createUpdate = url => data => (dispatch, _, httpService) => {
  dispatch(loadStart());

  return httpService
    .post({
      data,
      url,
    })
    .then(({ results: { token } }) => {
      if (token) {
        setToken({ token });
        dispatch(login(token));
      }
      dispatch(setFormData(data));
      return dispatch(loadSuccess());
    })
    .catch(error => dispatch(loadFail(error)));
};

export const create = createUpdate('/company/create');

export const update = createUpdate('/company/post');
