import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import AppLink from 'components/AppLink';
import { JobsForm } from 'components/Forms/JobsForm';
import ToastContent from 'ui/Toast/ToastContent';
import Form from 'components/Form';
import InputField from '../../../../../registration/ui/InputField';
import Select from 'ui/Select';
import { validateJob } from 'components/Forms/JobsForm';
import LocationInput from 'ui/LocationInput';
import Preloader from 'components/Preloader';
import Button from 'features/rfp/ui/Button';
import AcceptModal from './AcceptModal';
import QuestionnaireSection from '../QuestionnaireSection';
import EditorField from 'ui/EditorField';

import useJobs from '../../hooks/useJobs';
import useModal from 'hooks/useModal';

import bemCN from 'utils/bemCN';
import styles from './styles.styl';
import CheckboxRFP from 'features/registration/ui/CheckboxRFP';

const el = bemCN(styles)('EditJob');

// const TextAreaField = ({ error, fieldErrorClassName, className, ...props }) => {
//   return (
//     <textarea {...props} className={cn(className, { [fieldErrorClassName]: Boolean(error) })} />
//   );
// };

const customQuestionnaireSubject = 'Help me create a custom questionarie  ';
const customQuestionnaireBody =
  "Send us the following information and we will build the custom questionarie for you:%0D%0A %0D%0AQuestions you'd like to remove or ask additionally%0D%0AColors, images or other assets you'd like to use in the design";

const removeJobPostSubject = 'Job post deactivation request';

const getMailToHref = (subject, body = '') => `mailto:talent@80.lv?subject=${subject}&body=${body}`;
const mailToHref = {
  customQuestionnaire: getMailToHref(customQuestionnaireSubject, customQuestionnaireBody),
  removeJobPost: getMailToHref(removeJobPostSubject),
};

const EditJob = ({ id, onCreate, onUpdate, onCancel }) => {
  const { isLoading, loadJob, createJob, updateJob, selectedJob } = useJobs(id);
  useEffect(() => {
    id && loadJob(id);
  }, [loadJob, id]);

  const submit = useCallback(
    async form => {
      const content = {
        id: selectedJob?.content?.[0]?.id,
        type: 'description',
        content: {
          text: form.content,
        },
      };
      const data = {
        ...form,
        content: [content],
        is_active: form.is_active ? 1 : 0,
        categories: form.categories.map(item => item.value),
        styles: form.styles.map(item => item.value),
        tools: form.tools ? form.tools.map(item => item.value) : [],
      };

      if (isEmpty(form.content)) {
        delete data.content;
      }

      if (id) {
        const { payload: job, error } = await updateJob({ id, data });
        if (error) {
          const { message, name } = error || {};
          toast(<ToastContent type="error" title={name} body={message} />);
        } else {
          onUpdate && onUpdate(job);
        }
      } else {
        const { payload: job, error } = await createJob({ data });
        if (error) {
          const { message, name } = error || {};
          toast(<ToastContent type="error" title={name} body={message} />);
        } else {
          onCreate && onCreate(job);
        }
      }
    },
    [createJob, id, onCreate, onUpdate, selectedJob, updateJob],
  );

  const { isOpen, openModal, closeModal, ref, Modal: AcceptModalWrapper } = useModal();
  const { is_active: published } = selectedJob;
  const handleAccept = useCallback(
    onSubmit => {
      closeModal();
      onSubmit();
    },
    [closeModal],
  );
  return (
    <div className={el()}>
      <Preloader isLoaded={!isLoading}>
        {({ className: preloaderClassName }) => (
          <div className={cn(preloaderClassName, el('preloader'))}>
            <JobsForm
              job={selectedJob}
              validate={validateJob}
              onSubmit={submit}
              render={({
                TitleField,
                DescriptionField,
                LocationField,
                CategoriesField,
                ToolsField,
                StylesField,
                ExternalLinkCheckboxField,
                ExternalLinkField,
                handleSubmit,
                form,
              }) => {
                const { valid } = form;
                const { use_external_link } = form.values;
                return (
                  <Form onSubmit={handleSubmit} className={el('form', { edit: Boolean(id) })}>
                    <div className={el('fields', { edit: Boolean(id) })}>
                      <TitleField
                        className={el('field')}
                        component={InputField}
                        errorClassName={el('error')}
                      />
                      <DescriptionField
                        className={el('field-description')}
                        fieldErrorClassName={el('field-description--error')}
                        errorClassName={el('error')}
                        component={EditorField}
                      />
                      <CategoriesField
                        errorClassName={el('error')}
                        className={el('field')}
                        clearIndicatorClassName={el('selectIndicator')}
                        dropDownIndicatorClassName={el('selectIndicator')}
                        placeholderClassName={el('selectPlaceholder')}
                        controlClassName={el('select')}
                        controlErrorClassName={el('select', { error: true })}
                        inputClassName={el('select-input')}
                        component={Select}
                      />
                      <StylesField
                        errorClassName={el('error')}
                        className={el('field')}
                        clearIndicatorClassName={el('selectIndicator')}
                        dropDownIndicatorClassName={el('selectIndicator')}
                        placeholderClassName={el('selectPlaceholder')}
                        controlClassName={el('select')}
                        controlErrorClassName={el('select', { error: true })}
                        inputClassName={el('select-input')}
                        component={Select}
                      />
                      <ToolsField
                        errorClassName={el('error')}
                        className={el('field')}
                        clearIndicatorClassName={el('selectIndicator')}
                        dropDownIndicatorClassName={el('selectIndicator')}
                        placeholderClassName={el('selectPlaceholder')}
                        controlClassName={el('select')}
                        controlErrorClassName={el('select', { error: true })}
                        inputClassName={el('select-input')}
                        component={Select}
                      />
                      <LocationField
                        errorClassName={el('error')}
                        className={el('field')}
                        focusClassName={el('inputFocus')}
                        component={LocationInput}
                        FieldComponent={InputField}
                      />
                      <ExternalLinkCheckboxField
                        className={el('field')}
                        errorClassName={el('error')}
                        component={CheckboxRFP}
                      />
                      {use_external_link && (
                        <ExternalLinkField
                          className={el('field')}
                          component={InputField}
                          errorClassName={el('errorExternalLink')}
                        />
                      )}
                      {id && !use_external_link && (
                        <>
                          <QuestionnaireSection
                            id={id}
                            parent="edit"
                            request_id={selectedJob.request_id}
                          />

                          <div className={el('delete-section')}>
                            {`want to remove this listing? `} <br />
                            <AppLink
                              className={el('delete-section-link')}
                              targetBlank
                              href={mailToHref.removeJobPost}
                            >
                              EMAIL us
                            </AppLink>
                            {` to send a deletion request.`}
                          </div>
                        </>
                      )}
                    </div>

                    <div className={el('footer', { edit: Boolean(id) })} ref={ref}>
                      <Button
                        className={el('footer-btn')}
                        theme="black"
                        onClick={onCancel}
                        isUppercase={false}
                        isOutlined
                      >
                        Cancel
                      </Button>
                      <Button
                        className={el('footer-btn')}
                        theme="black"
                        type={published ? 'button' : 'submit'}
                        onClick={published ? openModal : undefined}
                        disabled={!valid}
                        isUppercase={false}
                      >
                        {id ? 'Save changes' : 'Create Job'}
                      </Button>

                      {published && (
                        <AcceptModalWrapper>
                          {isOpen && (
                            <AcceptModal
                              onClose={closeModal}
                              onAccept={() => handleAccept(handleSubmit)}
                            />
                          )}
                        </AcceptModalWrapper>
                      )}
                    </div>
                  </Form>
                );
              }}
            />
          </div>
        )}
      </Preloader>
    </div>
  );
};

export default EditJob;
