import React from 'react';

const UploadIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3C19 2.44772 18.5523 2 18 2C17.4477 2 17 2.44772 17 3V7.58579L15.7071 6.29289C15.3166 5.90237 14.6834 5.90237 14.2929 6.29289C13.9024 6.68342 13.9024 7.31658 14.2929 7.70711L17.2929 10.7071L18 11.4142L18.7071 10.7071L21.7071 7.70711C22.0976 7.31658 22.0976 6.68342 21.7071 6.29289C21.3166 5.90237 20.6834 5.90237 20.2929 6.29289L19 7.58579V3ZM6 7C5.44772 7 5 7.44772 5 8V13.5858L6.58579 12C7.36683 11.219 8.63317 11.219 9.41421 12L12 14.5858L13.5858 13C14.3668 12.219 15.6332 12.219 16.4142 13L17 13.5858V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V18C19 19.6569 17.6569 21 16 21H6C4.34315 21 3 19.6569 3 18V8C3 6.34315 4.34315 5 6 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H6ZM5 18V16.4142L8 13.4142L11.2929 16.7071L13.5858 19H6C5.44772 19 5 18.5523 5 18ZM17 18C17 18.4288 16.7301 18.7946 16.3509 18.9367L13.4142 16L15 14.4142L17 16.4142V18Z"
      fill="black"
    />
  </svg>
);

export default UploadIcon;
