import types from './types';

const initialState = {
  error: {},
  tools: [],
  styles: [],
  categories: [],
  isLoaded: false,
  isLoading: false,
};

export default function filtersReducer(state = initialState, { type, ...other }) {
  switch (type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILURE:
    case types.LOAD_SUCCESS:
      return { ...state, ...other };
    default:
      return state;
  }
}
