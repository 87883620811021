import types from './types';
import objectToFormData from 'utils/objectToFormData';

const loadProfileStart = () => ({
  type: types.LOAD_PROFILE_REQUEST,
  isLoaded: false,
  isLoading: true,
});

const loadProfileSuccess = () => ({
  type: types.LOAD_PROFILE_REQUEST,
  isLoading: false,
  isLoaded: true,
});

const setFormData = formData => ({
  type: types.SET_FORM_DATA,
  formData,
});

const setWorkTypes = workTypes => ({
  type: types.SET_WORK_TYPES,
  workTypes,
});

const loadProfileFail = error => ({
  type: types.LOAD_PROFILE_FAILURE,
  isLoading: false,
  isLoaded: false,
  error,
});

export const setAvatar = avatar => ({
  type: types.SET_AVATAR,
  avatar,
});

export const setId = id => ({
  type: types.SET_ID,
  id,
});

export const loadProfile = () => (dispatch, _, httpService) => {
  dispatch(loadProfileStart());
  return httpService
    .get({ url: '/profile/get' })
    .then(data => {
      const { avatar, work_types: workTypes, id, ...formData } = data;
      dispatch(setWorkTypes(workTypes));
      dispatch(setAvatar(avatar));
      dispatch(setFormData(formData));
      dispatch(setId(id));
      dispatch(loadProfileSuccess());
    })
    .catch(error => dispatch(loadProfileFail(error)));
};

export const updateProfile = data => (dispatch, _, httpService) => {
  dispatch(loadProfileStart());

  return httpService
    .post({
      url: '/profile/post',
      data: objectToFormData(data),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(() => {
      const { image, work_types: workTypes, ...formData } = data;
      if (image) {
        const reader = new FileReader();
        reader.onload = event => dispatch(setAvatar(event.target.result));
        reader.readAsDataURL(image);
      }
      dispatch(setWorkTypes(workTypes));

      dispatch(setFormData(formData));
      return dispatch(loadProfileSuccess());
    })
    .catch(error => dispatch(loadProfileFail(error)));
};
