import update from 'immutability-helper';

import imageCompress from '../../../utils/imageCompress';
import { getAssets, uploadAsset, deleteAsset } from './api';

const ROOT = '@art-builder/assets';

const START_LOADING = `${ROOT}/startLoading`;
const SET_IMAGES = `${ROOT}/setImages`;
const END_LOADING = `${ROOT}/endLoading`;
const ADD_IMAGE = `${ROOT}/addImage`;
const CHANGE_IMAGE = `${ROOT}/changeImage`;
const DELETE_IMAGE = `${ROOT}/deleteImage`;
const SET_FUNCTION = `${ROOT}/setFunction`;
const SET_CURRENT = `${ROOT}/setCurrent`;

const initialState = {
  images: [],
  current: null,
  setFunction: null,
  loading: false,
};

export const endLoading = () => ({ type: END_LOADING });

export const setCurrent = url => ({ type: SET_CURRENT, data: url });

export const initAssets = ({
  merchantId,
  projectId,
  collectionName,
  collectionId,
}) => async dispatch => {
  dispatch({ type: START_LOADING });
  const response = await getAssets({
    merchantId,
    projectId,
    collectionName,
    collectionId,
  });
  if (response.status !== 200) {
    dispatch(endLoading());
    return false;
  }
  dispatch({ type: SET_IMAGES, data: response.data });
  return dispatch({ type: END_LOADING });
};

export const addAssets = files => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;
  const promises = files.map(async file => {
    const finishFile = await imageCompress(file);
    const data = new FormData();
    data.append('file', finishFile, file.name);
    const { merchantId, projectId, _id: collectionId } = landing;
    return uploadAsset({
      merchantId,
      projectId,
      collectionId,
      data,
    }).then(res => {
      if (res && res.data) {
        dispatch(addImage(res.data));
      }

      return res;
    });
  });
  let responses = [];
  try {
    responses = await Promise.all(promises);
  } catch (error) {
    return false;
  }
  return responses;
};

export const uploadImage = ({
  merchantId,
  projectId,
  collectionName,
  collectionId,
  image,
  key,
  index,
  completeFunction,
}) => async dispatch => {
  dispatch({ type: ADD_IMAGE, data: false });
  const finishFile = await imageCompress(image);
  const data = new FormData();
  data.append('file', finishFile, image.name);
  const response = await uploadAsset({
    merchantId,
    projectId,
    collectionName,
    collectionId,
    data,
  });
  if (response.status !== 200) {
    dispatch(endLoading());
    return false;
  }
  dispatch({
    type: CHANGE_IMAGE,
    index,
    data: response.data,
  });
  if (completeFunction && key === 0) {
    completeFunction(response.data);
  }
  return response.data;
};

export const uploadImages = ({
  merchantId,
  projectId,
  collectionName,
  collectionId,
  images,
  completeFunction,
}) => (dispatch, getState) => {
  images.forEach((image, key) => {
    const {
      assets: { images },
    } = getState().artBuilderReducer;
    const index = images.length;
    dispatch(
      uploadImage({
        merchantId,
        projectId,
        collectionName,
        collectionId,
        image,
        key,
        index,
        completeFunction,
      }),
    );
  });
};

export const addImage = data => ({ type: ADD_IMAGE, data });

export const deleteImage = ({ merchantId, projectId, collectionId, id }) => async (
  dispatch,
  getState,
) => {
  const {
    assets: { images },
  } = getState().artBuilderReducer;
  const index = images.findIndex(image => image._id === id);
  const currentImage = images[index];
  dispatch({ type: DELETE_IMAGE, index });
  const response = await deleteAsset({
    merchantId,
    projectId,
    collectionId,
    id: currentImage._id,
  });
  if (response.status !== 204) {
    dispatch(addImage(currentImage));
    return false;
  }
  return true;
};

export const deleteImageByUrl = url => (dispatch, getState) => {
  const {
    assets: { images = [] },
  } = getState().artBuilderReducer;
  const currentImage = images.find(el => el.url === url);

  if (!currentImage) return;

  const { merchantId, projectId, collectionId, _id: id } = currentImage;
  dispatch(deleteImage({ merchantId, projectId, collectionId, id }));
};

export const deleteImagesByUrls = urls => dispatch =>
  urls.forEach(url => dispatch(deleteImageByUrl(url)));

export const setFunction = func => ({ type: SET_FUNCTION, func });

export default function(state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_IMAGES:
      return {
        ...state,
        images: action.data,
      };
    case SET_FUNCTION:
      return {
        ...state,
        setFunction: action.func,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ADD_IMAGE:
      return {
        ...state,
        images: state.images.concat(action.data),
      };
    case CHANGE_IMAGE:
      return {
        ...state,
        images: update(state.images, { [action.index]: { $set: action.data } }),
      };
    case DELETE_IMAGE:
      return {
        ...state,
        images: update(state.images, { $splice: [[action.index, 1]] }),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.data,
      };
    default:
      return state;
  }
}
