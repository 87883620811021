import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import useProfile from 'hooks/useProfile';
import { formattedAddress } from 'utils/formattedAddress';
import { Field, MultipleSelectField, UploadImageField, curryProps } from 'components/FinalForm';

const ProfileForm = ({ validate, onSubmit, ...props }) => {
  const {
    avatar: avatarUrl,
    workTypes,
    formData: {
      city,
      name,
      email,
      image,
      state,
      country,
      surname,
      position,
      workStatus,
      rfp_show_status,
      location_lat,
      location_lng,
      workTypesSelected,
      email_agreement,
    },
  } = useProfile();
  const [location, setLocation] = useState({ city, state, country });

  useEffect(() => {
    setLocation({ city, state, country });
  }, [city, country, state]);

  const NameField = useMemo(() => curryProps(Field, { name: 'name', label: 'Name' }), []);
  const EmailField = useMemo(() => curryProps(Field, { name: 'email', label: 'Email' }), []);
  const SurnameField = useMemo(() => curryProps(Field, { name: 'surname', label: 'Surname' }), []);
  const PositionField = useMemo(
    () => curryProps(Field, { name: 'position', label: 'Position' }),
    [],
  );

  const LocationField = useMemo(
    () =>
      curryProps(Field, {
        city,
        state,
        country,
        name: 'location',
        label: 'Location',
        onChange: fn => (v = {}) => {
          const { city, state, country } = v;
          fn(formattedAddress(city, state, country));
          setLocation(v);
        },
        placeholder: 'City, Country',
      }),
    [city, country, state],
  );

  const WorkStatusField = useMemo(
    () =>
      curryProps(Field, {
        name: 'workStatus',
        title: 'Ready for work',
        defaultChecked: workStatus,
        type: 'checkbox',
      }),
    [workStatus],
  );

  const RFPProfileShowStatusField = useMemo(
    () =>
      curryProps(Field, {
        name: 'rfp_show_status',
        title: 'Show my Profile on 80.lv',
        defaultChecked: rfp_show_status,
        type: 'checkbox',
      }),
    [rfp_show_status],
  );

  const EmailAgreementField = useMemo(
    () =>
      curryProps(Field, {
        name: 'email_agreement',
        title: 'Subscribe to our newsletter',
        defaultChecked: email_agreement,
        type: 'checkbox',
      }),
    [email_agreement],
  );

  const WorkTypesField = useMemo(
    () =>
      curryProps(MultipleSelectField, {
        name: 'workTypesSelected',
        label: 'Work types',
        title: 'Work types',
        options: workTypes,
      }),
    [workTypes],
  );

  const UploadAvatarField = useMemo(
    () =>
      curryProps(UploadImageField, {
        name: 'image',
        url: avatarUrl,
      }),
    [avatarUrl],
  );

  return (
    <FinalForm
      initialValues={{
        name,
        surname,
        image,
        position,
        email,
        workStatus,
        email_agreement,
        rfp_show_status,
        workTypesSelected,
        location: formattedAddress(city, state, country),
      }}
      validate={validate}
      onSubmit={data => onSubmit({ ...data, location_lat, location_lng, ...location })}
    >
      {({ submitError, handleSubmit, ...form }) =>
        props.render({
          NameField,
          SurnameField,
          PositionField,
          LocationField,
          WorkStatusField,
          RFPProfileShowStatusField,
          WorkTypesField,
          EmailField,
          UploadAvatarField,
          EmailAgreementField,
          submitError,
          handleSubmit,
          form,
        })
      }
    </FinalForm>
  );
};

ProfileForm.defaultProps = {
  onSubmit: null,
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  render: PropTypes.func,
  validate: PropTypes.func,
};

export default ProfileForm;
