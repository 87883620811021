import React from 'react';
import InputField from '../InputField';

const SpecializationField = props => (
  <InputField
    name="specialisation"
    label="Studio specialization"
    placeholder="Localization agency"
    {...props}
  />
);

export default SpecializationField;
