import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';
import startCase from 'lodash/startCase';

import useAnalytics from 'hooks/useAnalytics';
import CopyToClipboardField from '../CopyToClipboardField';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';
import Tooltip from 'ui/Tooltip';

const el = bemCN(styles)('ProfileLink');

const ProfileLink = ({ className, link = '', tooltip }) => {
  const defaultLink = location && location.origin + location.pathname;
  const fieldValue = link
    ? link.replace(/http[s]?:\/\//g, '')
    : defaultLink.replace(/http[s]?:\/\//g, '');
  const {
    query: { status, action },
  } = useRouter();
  const analyticEvents = useAnalytics();
  const { elementClick } = analyticEvents || {};
  const handleCopy = () =>
    elementClick('80lv_rfp_preview_copy-link', undefined, { popup: startCase(status || action) });

  return (
    <>
      <CopyToClipboardField
        text={link || defaultLink}
        value={fieldValue}
        className={cn(el(), className)}
        onCopy={handleCopy}
        data-tip={tooltip}
      />
      <Tooltip multiline />
    </>
  );
};

ProfileLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  tooltip: PropTypes.string,
};

export default ProfileLink;
