import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Router } from 'utils/routes';
import get from 'lodash/get';

import useProfile from 'hooks/useProfile';
import useAuth from 'features/auth/hooks/useAuth';
import useLogin from 'features/auth/hooks/useLogin';
import useRegistration from 'features/registration/hooks/useRegistration';
import useAnalytics from 'hooks/useAnalytics';
import { RoutesRFP } from 'config/routes';
import useModal from 'hooks/useModal';
import useSaveScrollPositionAfterRouteChange from 'hooks/useSaveScrollPositionAfterRouteChange';

import Button from '../../ui/Button';
import Popup from './Popup';
import Avatar from 'components/Avatar';
import ShareModal from '../ShareModal';
import FlashIcon from 'features/rfp/ui/icons/FlashIcon';

import styles from './styles.styl';
import bemCN from 'utils/bemCN';
import CaseIcon from 'features/rfp/ui/icons/CaseIcon';

const el = bemCN(styles)('Profile');

const getFullName = (name, surname) => `${name} ${surname}`;

function Profile({ palette }) {
  const {
    avatar,
    formData: { name, surname, email },
  } = useProfile();
  const { isAuthenticated } = useAuth();
  const { showFullscreenWidget } = useLogin();
  const { openRegistration } = useRegistration();
  const { elementClick } = useAnalytics();

  const handleLoginClick = () => {
    showFullscreenWidget();
    elementClick('80lv_rfp_login-widget_log-in');
  };

  const handleSignUpClick = () => {
    openRegistration();
    elementClick('80lv_rfp_login-widget_sign-up');
  };

  const router = useRouter();
  const { route, query } = router;
  const isProfilePage = useMemo(() => route === RoutesRFP.Profile.page, [route]);

  const profileOwnerEmail = useSelector(state =>
    get(state, 'artBuilderReducer.landing.landing.userModified.email'),
  );
  const isProfileOwner = useMemo(() => email === profileOwnerEmail, [email, profileOwnerEmail]);
  const canShareProfile = isProfilePage && isAuthenticated && isProfileOwner;

  const { action, domain } = query;
  const { openModal, closeModal, Modal: ModalWrapper, isOpen, ref } = useModal();
  useEffect(() => {
    if (canShareProfile && action === 'share') {
      openModal();
    } else if (isOpen) {
      closeModal();
    }
  }, [openModal, action, canShareProfile, closeModal, isOpen]);

  useSaveScrollPositionAfterRouteChange();

  const handleModalOpen = () => {
    Router.replaceRoute(RoutesRFP.Profile.name, { action: 'share', domain });
    elementClick('80lv_rfp_preview_show-popup', undefined, { popup: 'Share' });
  };
  const handleModalClose = async () => {
    await Router.replaceRoute(RoutesRFP.Profile.name, { domain });
    closeModal();
  };

  const { isPhone } = useSelector(state => state.responsive);

  if (isAuthenticated) {
    return (
      <div className={el('info')} ref={ref}>
        {canShareProfile && (
          <>
            <Button tag={'a'} href="/jobs" className={el('jobs-button')} theme="black" isOutlined>
              <CaseIcon className={el('jobs-button-icon')} />
              {!isPhone && 'Search jobs'}
            </Button>
            <Button
              className={el('share-button')}
              theme="black"
              isOutlined
              onClick={handleModalOpen}
            >
              <FlashIcon className={el('share-button-icon')} />
              {!isPhone && 'Share'}
            </Button>
          </>
        )}
        <Popup
          email={email}
          fullName={getFullName(name, surname)}
          button={toggle => (
            <div className={el('container-avatar')}>
              <Avatar image={avatar} size={38} className={el('avatar')} onClick={toggle} />
            </div>
          )}
        />
        {isOpen && (
          <ModalWrapper>
            <ShareModal onClose={handleModalClose} />
          </ModalWrapper>
        )}
      </div>
    );
  }

  return (
    <div className={el('info')} ref={ref}>
      <>
        <Button onClick={handleLoginClick} className={el('button')} theme={palette} isOutlined>
          Log in
        </Button>
        <Button onClick={handleSignUpClick} className={el('button')} theme={palette}>
          Sign up
        </Button>
      </>
    </div>
  );
}

Profile.propTypes = {
  palette: PropTypes.string,
};

export default Profile;
