import linkedin from './linkedin';
import facebook from './facebook';
import twitter from './twitter';
import artstation from './artstation';
import instagram from './instagram';

export default {
  linkedin,
  facebook,
  instagram,
  twitter,
  artstation,
};
