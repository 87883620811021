import axios from 'axios';

export const BASE_URL = process.env.SITEBUILDER_API_URL;

export const API_URL = process.env.API_URL;

const requestErrorHandler = promise => promise.then(res => res).catch(error => error.response);

export const sessionStart = async ({ domain }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/session/${domain}`,
      method: 'post',
      withCredentials: true,
    }),
  );

export const getStructure = async ({ merchantId, projectId, domain }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/structure`,
      method: 'get',
      withCredentials: true,
    }),
  );

export const createStructure = async ({ merchantId, projectId, domain }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/structure`,
    method: 'post',
    withCredentials: true,
  });

export const saveLandingMeta = async ({ domain, data, token }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/user_portfolio/${domain}`,
      headers: { token },
      method: 'post',
      data,
    }),
  );

export const startArtstationImport = async ({ domain, token, url, projects }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/user_portfolio/${domain}/parsing/start`,
      headers: { token },
      method: 'post',
      data: {
        type: 'artstation',
        projects,
        url,
      },
    }),
  );

export const stopArtstationImport = async ({ domain, token }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/user_portfolio/${domain}/parsing/stop`,
      headers: { token },
      method: 'post',
    }),
  );

export const getArtstationImportStatus = async ({ domain, token }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/user_portfolio/${domain}/parsing/status`,
      headers: { token },
      method: 'get',
    }),
  );

export const getArtstationProjects = async ({ url, token }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/portfolio/proxy/artstation`,
      headers: { token },
      method: 'post',
      data: { url },
    }),
  );

export const publicationLanding = async ({ token, domain }) =>
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      url: `/rfp/portfolio/publish?domain=${domain}`,
      method: 'get',
      headers: { token },
    }),
  );

export const updateFavorite = async ({ domain, isFavorite, token }) => {
  await requestErrorHandler(
    axios({
      baseURL: API_URL,
      method: 'post',
      url: '/rfp/company/portfolio/favorite',
      data: {
        domain,
        action: isFavorite ? 'add' : 'remove',
      },
      headers: { token },
    }),
  );
};

export const getPublishedLanding = async ({ domain, isCompanyRequest, token }) => {
  const commonPath = '/rfp/portfolio/structure';
  const companyPath = '/rfp/company/portfolio/structure';
  const path = isCompanyRequest ? companyPath : commonPath;

  const params = {
    baseURL: API_URL,
    url: `${path}?domain=${domain}`,
    method: 'get',
  };

  if (token) {
    params.headers = { token };
  }

  return await requestErrorHandler(axios(params));
};

export const createStructureParsingLanding = async ({ merchantId, projectId, domain, data }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/structure`,
    method: 'post',
    withCredentials: true,
    data,
  });

export const checkParsingLanding = async ({
  merchantId,
  projectId,
  domain,
  parserName,
  inputValue,
}) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/parsing?type=${parserName}&target=${inputValue}`,
    method: 'get',
    withCredentials: true,
  });

export const saveBlock = async ({ merchantId, projectId, landing, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/ui/${landing}/saveBlock`,
      method: 'put',
      withCredentials: true,
      data,
    }),
  );

export const deletePageBlock = async ({ merchantId, projectId, landingId, pageId, data }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
    method: 'delete',
    withCredentials: true,
    data,
  });

export const duplicatePageBlock = async ({ merchantId, projectId, landingId, pageId, data }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block/duplicate`,
    method: 'post',
    withCredentials: true,
    data,
  });

export const movePageBlock = async ({ merchantId, projectId, landingId, pageId, data }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
    method: 'put',
    withCredentials: true,
    data,
  });

export const addPageBlock = async ({ merchantId, projectId, landingId, pageId, data }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
    method: 'post',
    withCredentials: true,
    data,
  });

export const getAdditionalData = async ({ merchantId, projectId, landingId }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/additionaldata`,
      method: 'get',
      withCredentials: true,
    }),
  );

export const getFeatures = async ({ merchantId }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/features`,
      method: 'get',
      withCredentials: true,
    }),
  );

export const saveLandingValue = async ({ merchantId, projectId, landingId, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/savelandingsettings`,
      method: 'put',
      withCredentials: true,
      data,
    }),
  );

export const saveApplication = async ({ merchantId, projectId, domain, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/apps`,
      method: 'put',
      withCredentials: true,
      data,
    }),
  );

export const deleteApplication = async ({ merchantId, projectId, domain, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/apps`,
      method: 'delete',
      withCredentials: true,
      data,
    }),
  );

export const savePageValue = async ({ merchantId, projectId, landingId, pageId, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/savepagesettings`,
      method: 'put',
      withCredentials: true,
      data,
    }),
  );

export const uploadImage = async ({ merchantId, projectId, landingId, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/assets/${landingId}/site`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  );

export const deleteImage = async ({ merchantId, projectId, landingId, assetId }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/assets/${landingId}/${assetId}`,
    method: 'delete',
    withCredentials: true,
  });

export const createUserStorage = async ({ merchantId, projectId, landing }) =>
  await axios({
    baseURL: BASE_URL,
    url: `/merchant/${merchantId}/project/${projectId}/ui/${landing}/createlogin`,
    method: 'post',
    withCredentials: true,
  });

export const addLanguageToLanding = async ({ merchantId, projectId, domain, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/language`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  );

export const deleteLanguageFromLanding = async ({ merchantId, projectId, domain, data }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/language`,
      method: 'delete',
      withCredentials: true,
      data,
    }),
  );

export const getLauncherList = async ({ merchantId, projectId, landingId }) =>
  await requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/launcherList`,
      method: 'get',
      withCredentials: true,
    }),
  );
