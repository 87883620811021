/* eslint-disable no-console */
import axios from 'axios';
import isEmpty from 'ramda/es/isEmpty';
import extend from 'lodash/extend';
import { getToken } from 'features/auth/utils/auth';

export default class HttpService {
  static utilService;
  _errorHandler = ({ config, params, response } = {}) => {
    const errors = ['', `API Error::`];

    if (config) {
      errors[0] = `${errors[0]} ${config.method ? config.method.toUpperCase() : ''} ${config.url}`;
    }

    if (!isEmpty(params)) {
      errors.push(`Query: ${JSON.stringify(params)}`);
    }

    const data = (response && response.data) || null;

    if (data) {
      errors.push(`Code: ${data.status}`, `Message: ${data.name}`, data.message, '');
    }

    console.error(errors.join('\n'));
  };

  _request = (method, options) => {
    const {
      url = '',
      data = {},
      before = () => {},
      success = () => {},
      error = this._errorHandler,
      headers = {},
      baseURL = process.env.API_URL,
      paramsSerializer = null,
    } = options;

    const params = {};

    if (method === 'get') {
      extend(params, data);
    }

    before();
    const token = getToken();
    if (token) {
      headers.token = token;
    }
    return axios({
      method,
      baseURL,
      url,
      headers: { ...headers },
      params,
      paramsSerializer,
      data: method === 'get' ? {} : data,
    })
      .then(response => {
        success(response.data);
        return response.data;
      })
      .catch(err => {
        this.utilService.checkAuthError(err);
        if (err.response && err.response.status && err.response.status === 403) {
          return Promise.reject(
            this.utilService.rejectError({
              ...err,
              response: {
                ...err.response,
                data: {
                  ...err.response.data,
                  name: 'Request failed',
                  message:
                    'Sorry... Something went wrong! Please email us at support@80.lv for help.',
                },
              },
            }),
          );
        }
        error(err);
        return Promise.reject(this.utilService.rejectError(err));
      });
  };
  get = params => this._request('get', params);
  post = params => this._request('post', params);
  put = params => this._request('put', params);
  patch = params => this._request('patch', params);
  del = params => this._request('delete', params);
}
