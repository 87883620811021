import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import IconButton from 'features/rfp/ui/IconButton';
import CrossIcon from 'features/rfp/ui/icons/CrossIcon';

import styles from './styles.styl';
import bemCN from 'utils/bemCN';

const el = bemCN(styles)('ModernModal');

const ModernModal = ({
  className,
  showClose,
  closeIcon,
  contentClassName,
  footerClassName,
  closeClassName,
  content,
  closeModal,
  footer,
  closeOnOutsideClick = true,
  ...rest
}) => {
  const ref = useRef();

  const onOutsideClick = useCallback(
    e => {
      if (e.target === ref.current) {
        closeModal();
      }
    },
    [closeModal],
  );
  return (
    <div ref={ref} className={cn(el(), className)} {...rest}>
      <OutsideClickHandler onOutsideClick={closeOnOutsideClick ? onOutsideClick : () => {}}>
        <div className={cn(el('content'), contentClassName)}>
          {showClose && (
            <span className={cn(el('close'), closeClassName)}>
              {closeIcon || (
                <IconButton className={el('close-icon')} onClick={closeModal}>
                  <CrossIcon />
                </IconButton>
              )}
            </span>
          )}
          {content}
        </div>
        {footer && <div className={el('footer', footerClassName)}>{footer}</div>}
      </OutsideClickHandler>
    </div>
  );
};

ModernModal.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  closeClassName: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  closeModal: PropTypes.func,
  content: PropTypes.node.isRequired,
  closeIcon: PropTypes.node,
  showClose: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
};

export default ModernModal;
