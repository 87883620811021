const imageBasePath = '/static/images/rfp/instructions/tw';

export default {
  title: 'Twitter',
  link: 'https://www.twitter.com/',
  structure: [
    [
      {
        type: 'text',
        content:
          '2. Select Profile on the left, then click Edit profile button under your banner image',
      },

      {
        type: 'image',
        content: { src: `${imageBasePath}/1.png`, alt: 'Twitter profile' },
      },
    ],
    [
      { type: 'text', content: '3. Paste your profile link in the Website field' },
      { type: 'profileLink' },
      { type: 'image', content: { src: `${imageBasePath}/2.png`, alt: 'Edit profile' } },
    ],
    { type: 'text', content: '4. Click Save' },
  ],
};
