import { required, validateForm, email, url } from 'redux-form-validators';

export const validateNameExist = validateForm({
  name: required(),
});

export const validateCompanyRegistration = validateForm({
  name: required(),
  surname: required(),
});

export const validateStudioInfo = validateForm({
  title: [required()],
  specialisation: [required()],
  website: [required(), url()],
  location: [required()],
  email: [required(), email()],
  name: required(),
  surname: required(),
});

export const validateCompanyInfo = validateForm({
  title: [required()],
  website: [required(), url()],
  email: [required(), email()],
  name: required(),
  surname: required(),
});

export const validateArtistInfo = validateForm({
  name: required(),
  surname: required(),
  position: required(),
  location: required(),
  email: [required(), email()],
});
