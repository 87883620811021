import React from 'react';
import PropTypes from 'prop-types';
import { Field as FinalFormField } from 'react-final-form';

import FieldError from './FieldError';
import { optionsPropTypes } from './optionsPropTypes';

const getOptions = (items, selectedItems) =>
  items.map(({ id: value, type: label }) => ({
    value,
    label,
    checked: selectedItems.includes(value),
  }));

const MultipleSelectField = ({
  name,
  options,
  label,
  title,
  component: Component,
  className,
  errorClassName,
  ...props
}) => (
  <FinalFormField name={name}>
    {({ input, meta }) => {
      const changeOptions = (_, items) => {
        const itemsMapped = items.filter(({ checked }) => checked).map(({ value }) => value);
        return input.onChange(itemsMapped);
      };

      return (
        <>
          <Component
            onChange={changeOptions}
            name={label}
            label={label}
            title={title}
            className={className}
            options={getOptions(options, input.value)}
            {...props}
          />
          <FieldError className={errorClassName} meta={meta} />
        </>
      );
    }}
  </FinalFormField>
);

MultipleSelectField.propTypes = {
  name: PropTypes.string,
  options: optionsPropTypes,
  label: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.elementType,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default MultipleSelectField;
