import React from 'react';

import { Field } from 'components/FinalForm';
import ModernInput from 'features/rfp/ui/ModernInput';
import LocationInput from 'ui/LocationInput';
import { formattedAddress } from 'utils/formattedAddress';
import { profileSettingsPopupEl as el } from '../ProfileSettingsPopup';

const LocationField = ({ onChange, ...props }) => (
  <Field
    name="location"
    label="Location"
    component={LocationInput}
    FieldComponent={ModernInput}
    onChange={fn => (v = {}) => {
      const { city, state, country } = v;
      fn(formattedAddress(city, state, country));
      onChange(v);
    }}
    className={el('input-wrapper')}
    inputClassName={el('input')}
    focusClassName={el('inputFocus')}
    errorClassName={el('errorInput')}
    placeholder="City, Country"
    {...props}
  />
);

export default LocationField;
