import React from 'react';

import CheckboxField from '../CheckboxField';

const EmailAgreementField = ({ defaultChecked, ...props }) => (
  <CheckboxField
    name="email_agreement"
    title="Subscribe to our newsletter"
    defaultChecked={defaultChecked}
    {...props}
  />
);

export default EmailAgreementField;
