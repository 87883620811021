import React from 'react';

import { UploadImageField } from 'components/FinalForm';
import InputFile from 'components/InputFile';
import AvatarProfile from 'features/rfp/ui/AvatarProfile';

const UploadAvatarField = props => (
  <UploadImageField name="image" component={InputFile} cover={AvatarProfile} {...props} />
);

export default UploadAvatarField;
