import types from './types';

const initialState = {
  landing: { apps: {}, theme: {} },
  meta: {},
  locale: 'en-US',
  loading: true,
  loaded: false,
  isFavorite: false,
  isIframe: false,
  isServerSideRender: false,
  error: false,
  loadStatus: 200,
};

export default function landingReducer(state = initialState, action) {
  switch (action.type) {
    case types.INIT_LANDING:
      return { ...state, landing: action.landing };
    case types.INIT_META:
      return { ...state, meta: action.meta };
    case types.INIT_PROFILE:
      return { ...state, profile: action.profile };
    case types.INIT_PORTFOLIO:
      return { ...state, portfolio: action.portfolio };
    case types.INIT_COMPANY:
      return { ...state, company: action.company };
    case types.LANDING_IS_LOAD:
      return { ...state, loading: action.loading };
    case types.END_LOADING:
      return {
        ...state,
        loaded: action.loaded,
        loading: action.loading,
        isIframe: action.isIframe,
      };

    case types.SET_IS_FAVORITE:
      return { ...state, isFavorite: action.isFavorite };

    case types.UPDATE_LANDING:
      return { ...state, landing: action.landing };

    case types.CHANGE_LOCALE:
      return { ...state, locale: action.locale };

    case types.CHANGE_LANGUAGES_IN_LANDING:
      return { ...state, landing: action.landing, locale: action.locale };

    case types.SET_PREVIEW:
      return { ...state, isServerSideRender: action.isServerSideRender };

    case types.SET_ERROR:
    case types.UNSET_ERROR:
      return { ...state, loadStatus: action.loadStatus, error: action.error };
    default:
      return state;
  }
}
