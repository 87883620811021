import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  requestInviteCode,
  activateInviteCode,
  inviteCodeSelector,
  checkInviteCodeRequest,
} from '../state/inviteCode';
import useAuth from 'features/auth/hooks/useAuth';
import { setToken } from 'features/auth/utils/auth';

export const REQUEST_STATUS = {
  notProcessed: 'not_processed',
  rejected: 'rejected',
  sent: 'sent',
  expired: 'expired',
};

const useInviteCode = (inviteCode, onSubmit) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState(inviteCode);
  const [error, setError] = useState('');
  const { isAuthenticated, token: defaultToken, login } = useAuth();
  const { isLoading, isRequested, status, retryTimestamp } = useSelector(inviteCodeSelector);

  const isNeedRequest = useMemo(
    () => (isAuthenticated && !isRequested) || status === REQUEST_STATUS.expired,
    [isAuthenticated, isRequested, status],
  );

  useEffect(() => {
    if (isNeedRequest) {
      dispatch(checkInviteCodeRequest(defaultToken));
    }
  }, [defaultToken, dispatch, isNeedRequest]);

  const submitCode = useCallback(async () => {
    const { error, payload: token } = await dispatch(activateInviteCode(code));
    if (error) {
      return setError(error.message);
    }
    await setToken({ token });
    await login(token);
    await onSubmit();
  }, [code, dispatch, login, onSubmit]);

  const changeCode = useCallback(({ target: { value } }) => {
    const normalizedValue = value?.trim().toLowerCase();
    setCode(normalizedValue);
    setError('');
  }, []);

  const requestCode = useCallback(
    portfolioUrl => dispatch(requestInviteCode({ defaultToken, portfolioUrl })),
    [defaultToken, dispatch],
  );

  return {
    error,
    isLoading,
    changeCode,
    submitCode,
    requestCode,
    isNeedRequest,
    isRequested,
    status,
    retryTimestamp,
  };
};

export default useInviteCode;
