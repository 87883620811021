const imageBasePath = '/static/images/rfp/instructions/li';

export default {
  title: 'LinkedIn',
  link: 'https://linkedin.com',
  structure: [
    {
      type: 'text',
      content: '2. Click your profile image in the upper right and choose View Profile',
    },
    [
      {
        type: 'text',
        content: '3. Click the pencil icon to edit your profile',
      },
      {
        type: 'image',
        content: { src: `${imageBasePath}/1.png`, alt: 'Pencil icon on profile page' },
      },
    ],
    [
      { type: 'text', content: '4. Scroll down to Contact Info and click the pencil icon to edit' },
      { type: 'image', content: { src: `${imageBasePath}/2.png`, alt: 'Contact info field' } },
    ],
    [
      { type: 'text', content: '5. Choose + Add Website' },
      { type: 'image', content: { src: `${imageBasePath}/3.png`, alt: '+ Add Website field' } },
    ],
    [
      {
        type: 'text',
        content:
          '6. Paste your profile in the Website URL field and choose Portfolio from the pull-down menu',
      },
      { type: 'profileLink' },
      { type: 'image', content: { src: `${imageBasePath}/4.png`, alt: 'Filled form' } },
    ],
    {
      type: 'text',
      content: '7. Click the Apply button to save your profile URL on Linkedin',
    },
  ],
};
