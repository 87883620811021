import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import AppLink from 'components/AppLink';
import Heading from 'components/Heading';
import Page from 'components/Page';

import styles from './styles.styl';

const cx = cn.bind(styles);

function InternalServerError() {
  return (
    <div className={cx('InternalServerError')}>
      <Page.Section className={cx('InternalServerError__error')}>
        <div className={cx('InternalServerError__errorStatus')}>
          <img
            className={cx('InternalServerError__errorCode')}
            src="/static/images/errors/500.svg"
            alt="500_error"
          />
        </div>
        <div className={cx('InternalServerError__errorContent')}>
          <div className={cx('InternalServerError__row')}>
            <Heading className={cx('InternalServerError__errorTitle')} level={2}>
              Internal Server Error
            </Heading>
          </div>
          <div className={cx('InternalServerError__row')}>
            You can return to the
            <AppLink href="/" className={cx('InternalServerError__link')}>
              main page
            </AppLink>
          </div>
          <div className={cx('InternalServerError__row')}>or look at the selected articles</div>
        </div>
      </Page.Section>
    </div>
  );
}

InternalServerError.propTypes = {
  featured: PropTypes.object,
};

export default InternalServerError;
