import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';

const EditorField = ({
  error,
  fieldErrorClassName,
  className,
  onChange,
  onFocus,
  onBlur,
  value,
  ...props
}) => {
  const CKEditorRef = useRef();
  const EditorRef = useRef();

  useEffect(() => {
    const { CKEditor } = require('@ckeditor/ckeditor5-react');
    CKEditorRef.current = CKEditor;
    const { InlineEditor } = require('../../ckeditor/ckeditor');
    require('../../ckeditor/styles.css');
    EditorRef.current = InlineEditor;
  }, []);

  const placeholder =
    '<h2>Job description</h2><p>Use this block to describe your job to candidates</p>';

  if (CKEditorRef.current && EditorRef.current) {
    const CKEditor = CKEditorRef.current;
    const Editor = EditorRef.current;

    return (
      <div className={cn(className, { [fieldErrorClassName]: Boolean(error) })} onFocus={onFocus}>
        <CKEditor
          editor={Editor}
          data={value}
          config={{
            link: {
              addTargetToExternalLinks: true,
            },
            toolbar: {
              shouldNotGroupWhenFull: false,
            },
            initialData: value || placeholder,
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            if (isEqual(data, placeholder)) {
              return onChange('');
            }
            onChange(data);
          }}
          onFocus={(event, editor) => {
            const data = editor.getData();
            // console.log(data);
            if (data === placeholder) {
              editor.setData('');
            }
          }}
          onBlur={(event, editor) => {
            const data = editor.getData();
            if (!data) {
              editor.setData(placeholder);
            }
            onBlur(event);
          }}
          {...props}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default EditorField;
