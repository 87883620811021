import React from 'react';
import PropTypes from 'prop-types';
import ProfileLink from '../ProfileLink';
import instructions from './instructions';
import bemCN from 'utils/bemCN';

import Link from '../Link';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import uniqueId from 'lodash/uniqueId';
import styles from './InstructionPage.styl';

const el = bemCN(styles)('InstructionPage');

const InstructionPage = ({ instructionKey }) => {
  const instruction = instructions[instructionKey];
  if (!instruction) return null;

  const { structure, title, link } = instruction;
  const firstStep = (
    <li key="first" className={el('step')}>
      1. Log into your{' '}
      <Link className={el('link')} href={link} targetBlank>
        {title}
      </Link>{' '}
      account
    </li>
  );
  const renderNode = ({ type, content }) => {
    switch (type) {
      case 'text':
        return <p key={uniqueId()}>{content}</p>;
      case 'image':
        return <img key={uniqueId()} className={el('image')} alt={content.alt} src={content.src} />;
      case 'profileLink':
        return <ProfileLink key={uniqueId()} />;
      default:
        return null;
    }
  };
  const steps = structure.map(node => {
    if (isArray(node)) {
      return (
        <li key={uniqueId()} className={el('step', { composite: true })}>
          {node.map(renderNode)}
        </li>
      );
    }
    if (isObject(node)) {
      return (
        <li key={uniqueId()} className={el('step')}>
          {renderNode(node)}
        </li>
      );
    }
  });

  return (
    <div className={el()}>
      <ol>{[firstStep, ...steps]}</ol>
    </div>
  );
};

InstructionPage.propTypes = {
  instructionKey: PropTypes.string,
};

export default InstructionPage;
