import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

function IconButton({ children, className, onClick, ...restProps }) {
  const buttonClassNames = cx('IconButton', className);

  return (
    <button className={buttonClassNames} onClick={onClick} type="button" {...restProps}>
      {children}
    </button>
  );
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
export default IconButton;
