import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import AppLink from 'components/AppLink';

import styles from './styles.styl';

const cx = cn.bind(styles);

function Link({ className, href, onClick, ...other }) {
  const { pathname } = useRouter();
  const isCurrent = useMemo(() => pathname === href, [href, pathname]);

  return (
    <AppLink
      href={href}
      onClick={onClick}
      className={cx('Link', { Link_current: isCurrent }, className)}
      {...other}
    />
  );
}

Link.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
};

export default Link;
