import { useCallback, useEffect, useReducer, useRef } from 'react';
const defaultSrc = { src: '', src2x: '' };
export const makeObjectFromSrc = image =>
  typeof image === 'string'
    ? {
        src: image,
        original: image,
        current: getOriginal({ src: image }),
        src2x: null,
      }
    : {
        ...image,
        current: image ? getOriginal(image) : defaultSrc,
      };

const getOriginal = ({ src, src2x }) =>
  window ? (window.devicePixelRatio > 1 && src2x ? src2x : src) : src;

const defaultData = {
  src: null,
  original: null,
  src2x: null,
  current: null,
};

const initialState = {
  isLoaded: false,
  ...defaultData,
};

const reducer = (state, { src, original, src2x, current, isLoaded, type }) => {
  switch (type) {
    case 'SET_DATA':
      return { ...state, src, original, src2x, current };
    case 'SET_LOADED':
      return { ...state, isLoaded };
    default:
      throw new Error('undefined type');
  }
};

const configureRemoveOnload = object => () => {
  if (object && object.current) {
    object.current.onload = undefined;
  }
};

const useMedia = (data, removeOnload) => {
  const [{ src, original, src2x, current, isLoaded }, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: 'SET_DATA', ...makeObjectFromSrc(data) });
    return () => removeOnload();
  }, [data, removeOnload]);

  return {
    isLoaded,
    src,
    original,
    src2x,
    current,
    dispatch,
  };
};

export const useVideo = data => {
  const videoRef = useRef(null);
  const removeOnload = useCallback(configureRemoveOnload(videoRef), [videoRef]);

  const { isLoaded, src, current, dispatch } = useMedia(data, removeOnload);

  const load = useCallback(() => {
    videoRef.current = document.createElement('video');
    videoRef.current.src = src;
    videoRef.current.onloadeddata = () => {
      dispatch({ type: 'SET_LOADED', isLoaded: true });
    };
  }, [dispatch, src]);

  return {
    isLoaded,
    src,
    current,
    load,
  };
};

export const useImage = data => {
  const imgRef = useRef(null);
  const removeOnload = useCallback(configureRemoveOnload(imgRef), [imgRef]);

  const { isLoaded, src, original, src2x, current, dispatch } = useMedia(data, removeOnload);

  const load = useCallback(() => {
    if (current) {
      imgRef.current = new Image();
      imgRef.current.src = current;
      imgRef.current.onload = () => {
        if (imgRef && imgRef.current && imgRef.current.onload) {
          dispatch({ type: 'SET_LOADED', isLoaded: true });
        }
      };
    }
  }, [current, dispatch]);

  return {
    isLoaded,
    src,
    original,
    src2x,
    current,
    load,
  };
};
