const imageBasePath = '/static/images/rfp/instructions/fb';

export default {
  title: 'Facebook',
  link: 'https://www.facebook.com/',
  structure: [
    {
      type: 'text',
      content: '2. Select your profile image',
    },
    [
      {
        type: 'text',
        content: '3. Click About tab and choose Contact and Basic Info',
      },
      {
        type: 'image',
        content: { src: `${imageBasePath}/1.png`, alt: 'About tab' },
      },
    ],
    [
      { type: 'text', content: '4. Click Add a website and paste your profile link' },
      { type: 'profileLink' },
      { type: 'image', content: { src: `${imageBasePath}/2.png`, alt: 'Add a website field' } },
    ],
    { type: 'text', content: '5. Click Save' },
  ],
};
