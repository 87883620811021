import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createJob as create,
  loadJobs as loadAll,
  loadJob as load,
  updateJob as update,
  sendJob as send,
  publishJob as publish,
} from '../state/jobs';

import { jobsSelector, specificJobSelector } from '../state/jobs/selectors';

import useAuth from 'features/auth/hooks/useAuth';
import Preloader from 'components/Preloader';
import { notify, notifyError } from 'ui/Toast';
import useAnalytics from 'hooks/useAnalytics';

export default function useJobs(id) {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { customEvent } = useAnalytics();
  const { allIds, byIds, isLoaded, isLoading, items } = useSelector(jobsSelector);

  const loadJobs = useCallback(() => token && dispatch(loadAll()), [dispatch, token]);

  const selectedJob = useSelector(state => specificJobSelector(state, id));

  const createJob = useCallback(
    async ({ data }) => {
      //customEvent('80lv_rfp_profile_rfp-send', { domain, rfp_id: selected[0] });
      return await dispatch(create({ data }));
    },
    [dispatch],
  );

  const updateJob = useCallback(
    async ({ id, data }) => {
      //customEvent('80lv_rfp_profile_rfp-send', { domain, rfp_id: selected[0] });
      return await dispatch(update({ id, data }));
    },
    [dispatch],
  );

  const loadJob = useCallback(
    async id =>
      //customEvent('80lv_rfp_profile_rfp-send', { domain, rfp_id: selected[0] });
      token && (await dispatch(load({ id }))),
    [dispatch, token],
  );

  const sendJob = useCallback(
    async (jobId, domain) => {
      customEvent('80lv_rfp_profile_rfp-send', { domain, job_id: jobId });
      return await dispatch(send({ jobId, domain }));
    },
    [customEvent, dispatch],
  );

  const publishJob = useCallback(
    async jobId => {
      try {
        const { error } = await dispatch(publish(jobId));
        if (error) {
          throw error;
        } else {
          notify({ type: 'success', title: 'Success', body: 'The Job is published!' });
        }
      } catch (e) {
        notifyError(e);
      }
    },
    [dispatch],
  );

  const isEmpty = !allIds.length;

  const withPreloader = useCallback(
    (listRender, stub) => (
      <Preloader isLoaded={!isLoading}>
        {({ className: preloaderClassName }) =>
          isEmpty && isLoaded ? stub : listRender(preloaderClassName)
        }
      </Preloader>
    ),
    [isEmpty, isLoaded, isLoading],
  );

  return {
    createJob,
    selectedJob,
    updateJob,
    loadJobs,
    loadJob,
    sendJob,
    publishJob,
    isEmpty,
    isLoaded,
    isLoading,
    withPreloader,
    jobs: allIds,
    jobById: byIds,
    jobItems: items,
  };
}
