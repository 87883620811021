import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { mediaQueryTracker, reducer as responsive } from 'redux-mediaquery';
import breakpoints from 'config/breakpoints';

import modal from './modal';
import company from './company';
import auth from 'features/auth/state/auth';
import gdpr from './gdpr';
import profile from './profile';
import artBuilderReducer from 'features/artBuilder/state/reducers';
import inviteCodeReducer from 'features/registration/state/inviteCode';
import rfpReducer from 'features/rfp/state/reducer';
import HttpService from 'utils/httpService';
import configureUtilService from 'utils/configuratorUtilService';

function CapitlizeString(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const reducer = {
  responsive,
  modal,
  company,
  auth,
  gdpr,
  profile,
  artBuilderReducer,
  inviteCodeReducer,
  rfpReducer,
};

export const initStore = (initialState = {}) => {
  const httpService = new HttpService();
  const customizedMiddleware = getDefaultMiddleware({
    thunk: {
      extraArgument: httpService,
    },
  });

  const store = configureStore({
    reducer: reducer,
    middleware: customizedMiddleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  });

  httpService.utilService = configureUtilService(store);

  const MQuery = {};
  Object.keys(breakpoints).forEach(breakpoint => {
    const query = breakpoints[breakpoint];

    MQuery[`is${CapitlizeString(breakpoint)}`] = `screen and (max-width: ${query}px)`;
  });

  store.dispatch(
    mediaQueryTracker({
      ...MQuery,
      isOnlyTablet: `screen and (min-width: ${breakpoints.phone + 1}px) and (max-width: ${
        breakpoints.tablet
      }px)`,
      innerWidth: true,
      innerHeight: true,
    }),
  );
  return store;
};
