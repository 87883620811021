import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

const ModernInput = ({
  className,
  error,
  disabled,
  focusClassName,
  inputClassName,
  label,
  name,
  onBlur,
  onFocus,
  ...other
}) => {
  const [focus, setFocus] = useState(false);

  const onFocusHandler = e => {
    setFocus(true);
    onFocus(e);
  };

  const onBlurHandler = e => {
    setFocus(false);
    onBlur(e);
  };

  return (
    <label
      className={cx('ModernInput', className, {
        'ModernInput--focus': focus,
        [focusClassName]: focus,
        'ModernInput--disabled': disabled,
      })}
    >
      {label && (
        <span
          className={cx('ModernInput__label', {
            'ModernInput__label--focus': focus,
          })}
        >
          {label}
        </span>
      )}
      <input
        id={name}
        type="text"
        name={name}
        className={cx('ModernInput__input', inputClassName, {
          'ModernInput__input--error': error,
        })}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
        {...other}
      />
      {error && (
        <img
          alt="error-mark"
          src="/static/images/rfp/error-mark.svg"
          className={cx('ModernInput__error-mark')}
        />
      )}
    </label>
  );
};

ModernInput.defaultProps = {
  className: '',
  error: '',
  disabled: false,
  focusClassName: '',
  inputClassName: '',
  label: '',
  name: '',
  onBlur: () => {},
  onFocus: () => {},
};

ModernInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  focusClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default ModernInput;
