import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { toast } from 'react-toastify';
import uniqueId from 'lodash/uniqueId';
import startCase from 'lodash/startCase';
import copy from 'copy-to-clipboard';

import NextLink from 'components/NextLink';
import Button from 'features/rfp/ui/Button';
import Toast from 'features/rfp/ui/Toast';
import CopyLinkIcon from 'features/rfp/ui/icons/CopyLinkIcon';
import useAnalytics from 'hooks/useAnalytics';
import useCurrentArtistPortfolios from 'features/rfp/hooks/useCurrentArtistPortfolios';

import shareSocials from 'features/rfp/utils/shareSocials';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('ShareButtons');

const CopyLinkButton = ({ link }) => {
  const {
    query: { status, action },
  } = useRouter();
  const analyticEvents = useAnalytics();
  const { elementClick } = analyticEvents || {};

  const handleCopyClick = () => {
    copy(link);
    toast(<Toast type="success" title="Success" body="Your link has been copied" />);
    elementClick('80lv_rfp_preview_copy-link', undefined, { popup: startCase(status || action) });
  };
  return (
    <Button
      className={cn(el('item'), el('item-wrapper'))}
      isOutlined
      theme="black"
      onClick={handleCopyClick}
    >
      <span className={el('item-icon')} title={name}>
        <CopyLinkIcon />
      </span>
      Copy link
    </Button>
  );
};

CopyLinkButton.propTypes = {
  link: PropTypes.string.isRequired,
};

const ShareButtons = ({ link, className }) => {
  const {
    query: { status, action },
  } = useRouter();
  const analyticEvents = useAnalytics();
  const { elementClick } = analyticEvents || {};
  const handleShareClick = provider => () =>
    elementClick('80lv_rfp_preview_click-share', provider.toLowerCase(), {
      popup: startCase(status || action),
    });

  const { portfolios } = useCurrentArtistPortfolios();
  const portfolio = portfolios[0];
  const previewUrl = portfolio && portfolio.url_preview;

  return (
    <div className={cn(el(), className)}>
      {shareSocials
        .map(({ name, shortName, shareAction, Icon, getShareLink }) => {
          return (
            <NextLink
              className={el('item-wrapper')}
              key={uniqueId()}
              href={getShareLink(link, previewUrl)}
              targetBlank
            >
              <Button
                className={el('item', { [shortName]: Boolean(shortName) })}
                isOutlined
                theme="black"
                onClick={handleShareClick(name)}
              >
                <span className={el('item-icon')} title={name}>
                  <Icon width="16px" height="16px" />
                </span>
                {shareAction}
              </Button>
            </NextLink>
          );
        })
        .concat(<CopyLinkButton key={uniqueId()} link={link} />)}
    </div>
  );
};

ShareButtons.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShareButtons;
